.tutorial-header {
    padding: 2.2vw;
    font-size: 1.25vw;
    font-weight: 600;
    color: #000;
    text-align: center;
    background-color: #DDEAFF;
    border-bottom: 0.0625vw solid #91bbfe;

    a {
        text-decoration: none;
        color: #002b79;

        &:hover {
            text-decoration: underline;
        }
    }
}

.tutorial-header>h1 {
    font-size: 2.125vw;
    margin-top: 0;
    margin-bottom: 0.3125vw;
    color: #01183d;
}

.tutorial-header>p {
    margin-top: 0;
    font-size: 1.2vw;
    font-weight: 400;
    color: #000000;
    opacity: 0.8;
}

.tutorial-content {
    padding: 0 1vw;
    max-width: 87.5vw;
    margin: 0 auto;
}

.tutorial-type {
    margin: 3vw 0;
    color: #2E82E8;
    font-size: 1.5vw;
}

.tutorial-product-name {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    a {
        text-decoration: none;

        h3 {
            margin: 0;
            font-size: 1.125vw;
            font-weight: bold;
            white-space: nowrap;
            color: #007bff;
        }
    }

    div {
        width: 85vw;

        hr {
            margin: 0.6vw 0vw 0.6vw 1.1vw;
        }
    }
}

.tutorial-container {
    display: flex;
    margin: 1.875vw 0 0 1.25vw;
    flex-wrap:
}

.player {
    div {
        .react-slideshow-container {
            .react-slideshow-wrapper {
                border: unset;
                border-radius: unset;
            }
        }
    }
}

.manual {
    width: 15%;
    display: flex;
    align-items: center;

    a {
        text-decoration: none;
    }

    a>svg {
        width: 6.25vw;
        height: auto;
    }

    a>p {
        color: #007bff;
        margin: 1vw 0;
        font-size: 1.0625vw;

        &:hover {
            text-decoration: underline;
        }
    }
}

.tutorial-manual {
    width: 85%;
}

.play-icon {
    width: 3vw;
    height: 3vw;
    z-index: 1;
    left: 45%;
    top: 30%;
    position: absolute;
}

.tutorial-image {
    position: relative;
    border: none;
    background-color: unset;
    width: 100%;

    img {
        border-radius: 0.625vw;
        width: 95%;
        pointer-events: none;
    }

    p {
        font-size: 1vw;
    }
}

.faq-icon {
    width: 60%;
    object-fit: cover;
}