.discussion {
    padding: 2.5vw;
}

.discussion-title {
    font-size: 1.667vw;
    margin: 0;
    text-align: center;
}

.product-discussion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.discussion-topic {
    font-size: 1.25vw;
}

.discussion-list {
    width: 66vw;
    margin: 0 auto;
    border-collapse: collapse;
}

.discussion-list>tbody>tr>td {
    border-top: none;
    padding: 0.764vw 0;
    font-size: 1.25vw;
}

.discussion-list>tbody>tr>td:nth-child(1) {
    width: 3%;
}

.discussion-list>tbody>tr>td:nth-child(2) {
    width: 97%;

    a {
        text-decoration: none;
        color: black;
    }
}


.discussion-list>tbody>tr {
    border-bottom: 0.069vw solid rgb(129, 123, 123, 0.5);
    border-top: none;
    text-align: left;
}

.discussion-list>tbody>tr>td>a {
    text-decoration: none;
}

@media screen and (max-width:430px) {
    .discussion {
        padding: 5vw;
    }

    .discussion-title {
        font-size: 5vw;
    }

    .product-discussion {
        width: 100%;
    }

    .discussion-topic {
        font-size: 2.986vw;
    }

    .discussion-list {
        width: 100%;
    }

    .discussion-list>tbody>tr>td {
        border-top: none;
        padding: 0.764vw 0;
    }

    .discussion-list>tbody>tr>td:nth-child(1) {
        width: 5%;
        font-size: 2.986vw;
    }

    .discussion-list>tbody>tr>td:nth-child(2) {
        width: 95%;
    }

}