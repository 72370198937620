.image-slider{
    position: relative;
}

.react-slideshow-wrapper{
    border: 2vw solid white;
    border-radius: 4vw;
}

.react-slideshow-container+ul.indicators {
    padding: 0.6vw;
    margin: 0;
    position: absolute;
    bottom: -3vw;;
    left: 44.3%;
    border: 1px solid;
    border-radius: 4vw;
    background-color: #F4F4F4;

    li{
        width: 0;
        height: 0;
        padding: 0vw 0.7vw 1vw 0.7vw;
        button{
            background-color: white;
            border: none;
            width: 0.833vw;
            height: 0.833vw;
            border-radius: 50%;
            margin: 0.2vw;
            cursor: pointer;
        }
    
    }
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
    width: 0.833vw;
    height: 0.833vw;
    left: 3px;
    border: 1px solid #5A97C3;
    background: #5A97C3;
}

@media screen and (max-width:430px) {
    .image-slider {
        .image-box {
                img {
                    width: 96%;
                    height: 28vw;
                    border: 2vw solid white;
                    border-radius: 5vw;
                    object-fit: cover;
                    object-position: center;
                }
        }
    }
}