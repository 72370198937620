.navigation {
    background-color: black;
    max-width: none;
    padding: 0 1.111vw;
}

.navbar {
    display: flex;
    justify-content: center;
    left: 0;
    top: 0;
    position: relative;
    height: 4.5vw;
    background: black;
    z-index: 11;
    max-width: 104.167vw;
    margin: auto;
}

.menu-bar {
    display: none;
}

.logo {
    width: 85%;
    position: relative;
}

.content {
    -webkit-box-align: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-bottom: 0;
    padding-top: 0;
    width: 75%;
    font-size: 1.111vw;
    position: relative;
}

.content-left {
    display: flex;
    align-items: center;
    width: 15%;
}

.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100%;
    width: 90%;
}

.items {
    font-size: 1.125vw;
}

.navbar .items {
    align-items: center;
    display: flex;
    text-decoration-line: unset;
    color: var(--background-color);
    gap: 0.347vw;
    padding: 1.636vw;
    margin: 0;
    flex-wrap: nowrap;
}

.down-arrow {
    width: 1.042vw;
    padding: 0.347vw 0;
}

.content-right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    object-fit: contain;
    width: 15%;
    margin: auto;
}

.language-icon {
    position: absolute;
    left: 0.972vw;
    color: var(--background-color);
    font-size: 1.771vw;
}

.language-dropdown {
    position: absolute;
    right: 0.611vw;
    color: var(--background-color);
    font-size: 1.771vw;
}

.select-language {
    width: 10%;
    display: flex;
    position: relative;
    align-items: center;
    padding-left: 0.799vw;
}

#sel {
    outline: thin white solid;
    padding-left: 1.883vw;
    border-radius: 0.347vw;
    cursor: pointer;
    height: 2.672vw;
    font-size: 1.222vw;
    width: 100%;
    appearance: inherit;
    color: var(--background-color);
    background-color: black;

    option {
        background-color: rgb(47, 47, 47);
    }
}

.auth {
    display: contents;
    width: 100%;
}

.signin {
    text-decoration: none;
    text-wrap: nowrap;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    color: var(--text-color);
    font-size: 1.125vw;
    border: 0.069vw solid var(--background-color);
    border-radius: 0.278vw;
    width: 10%;
    margin: auto;
    position: relative;
    height: 2.672vw;
}

.signin:active {
    background-color: var(--text-color);
    color: var(--background-color);
}

.product-dropdown {
    display: none;
}

.header-item {
    position: relative;
}

.support-list {
    display: flex;
    flex-direction: column;
    width: 97%;
}

.items-dropdown {
    display: none;
}

.product-type {
    padding: 1.139vw 0 1.139vw 0.694vw;
    justify-content: flex-start;
    display: flex;
    text-align: center;
    margin: 0;
    color: #1473e6;
    background-color: white;
    font-size: 1.125vw;

}

.product-block {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.product-content {
    display: flex;
    flex-direction: column;
    height: 21vw;
}

.product-detail {
    width: 92%;
    display: inline-block;
    padding: 0.711vw 0.933vw 0.711vw;
    border-top: 0.069vw solid #CCC;
    text-decoration: none;
    flex-grow: 1;
    transition: background-color 0.3s ease;
}

.product-detail:last-child {
    height: 100%;
}

.product-detail:last-child:hover {
    background-color: white;
    border-end-end-radius: 0.694vw;
    border-end-start-radius: 0.694vw;
}

.product-list {
    display: flex;
    flex-direction: column;
    height: 20vw;
}

.product-name {
    margin: 0 0 0.583vw 0;
    color: black;
    font-weight: 600;
    font-size: 0.9375vw;
}

.product-description {
    margin: 0;
    color: black;
    font-size: 0.9375vw;
}

.product-block {
    background-color: rgb(238, 238, 238);
    border-radius: 0.694vw;
    width: 25%;
}

/* Hover views */
@media (hover: hover) and (any-hover: hover) and (min-width: 430px) {
    .navbar a.deskshare-logo :hover {
        text-decoration: none;
    }

    .navbar .items:hover {
        background-color: var(--background-color);
        color: var(--text-color);

        .down-arrow {
            transform: rotate(180deg);
        }
    }

    #sel:hover {
        ~svg {
            transform: rotate(180deg);
        }
    }


    .product-container:hover {
        .product-dropdown {
            /* width: 92.5vw; */
            width: 131%;
            /* left: -12.028vw; */
            left: -18%;
            display: flex;
            gap: 0.694vw;
            background-color: white;
            position: absolute;
            top: 4.503vw;
            border-radius: 0 0 0.694vw 0.694vw;
            box-shadow: 0 0.447vw 0.6vw 0;
        }

        .items-dropdown {
            display: flex;
            background-color: rgb(238, 238, 238);
            position: absolute;
            left: 0;
            top: 4.703vw;
            width: 18vw;
            box-shadow: 0 0.447vw 0.6vw 0;
            border-radius: 0 0 0.694vw 0.694vw;

        }
    }

    .product-detail:hover {
        background-color: white;
        box-shadow: 0.139vw 0.139vw 0.444vw 0 black;
    }
}

/* Mobile View */

@media only screen and (max-width: 430px) {
    .navbar {
        display: flex;
        align-items: center;
        left: 0;
        top: 0;
        height: 6.661966vh;
        position: relative;
        padding: 0 1vw;
        background: black;
        width: -webkit-fill-available;
    }

    .menu-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10%;
        font-size: 6vw;
        background-color: black;
        border: none;
    }

    .menu-button {
        color: var(--background-color);
    }

    .content-left {
        display: flex;
        width: 70%;
        justify-content: center;
    }

    .deskshare-logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .logo {
        width: 35vw;
    }

    .content {
        width: 0%;
        overflow: hidden;
        display: none;
    }

    .other-view-content {
        left: 0;
        top: 6.55vh;
        position: fixed;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        background-color: rgb(228, 231, 239);
        width: 100%;
        height: 100%;
        transition: 0.5s;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 1.617723vh - 16.115912vh);
    }

    .content-center {
        color: var(--background-color);
        display: flex;
        justify-content: left;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: unset;
        margin: unset;
    }

    .product-container>span {
        margin-left: 3vw;
    }

    .navbar .items {
        color: var(--text-color);
        font-size: 5vw;
        align-items: center;
        text-decoration-line: unset;
        display: block;
        font-weight: 600;
        width: 100%;
        border-bottom: 1px solid var(--text-color);

        div {
            width: 100%;

            .product-dropdown {
                display: block;
                margin: 1vw 0 0 5vw;

                .product-block {
                    background-color: unset;

                    .product-content {
                        .product-type {
                            display: none;
                        }

                        .product-list {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }

    .product-detail {
        border-top: none;
        width: 100%;

        .product-name {
            font-size: 4vw;
            font-weight: 300;
        }

        .product-description {
            display: none;
        }
    }

    #download {
        display: none;
    }

    .items-dropdown {
        display: block;
        margin: 1vw 0 0 5vw;
    }

    .down-arrow {
        display: none;
    }

    .select-language {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 20%;
    }

    .language-icon {
        color: white;
        left: 5.5vw;
        font-size: 5vw;
    }

    #sel {
        outline: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 2vw;
        appearance: inherit;
        background-color: black;
        color: black;
        height: 8vw;
        width: auto;
        padding-left: 5px;
    }

    #sel>option {
        background-color: black;
        color: white;
        font-size: 35px
    }

    .language-dropdown {
        color: white;
        position: absolute;
        right: 5vw;
        top: 1.4vw;
        font-size: 5vw;
    }

    .auth {
        margin-top: 5vw;
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0vw;
        height: 20vw;
        align-items: center;
        background-color: white;
        border-top: 1px solid black;

        .signin {
            margin: 0;
            background-color: #2A69CA;
            color: white;
            border-radius: 1vw;
            width: 28vw;
            height: 10vw;
            font-size: 5vw;
        }
    }
}