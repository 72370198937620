.home-footer {
    border-bottom: 0.069vw solid rgba(128, 128, 128, 0.671);
    gap: 5vw;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    padding: 1.25vw 6.25vw 1.25vw 6.25vw;
}

.left-block {
    display: flex;
    gap: 7.986vw;
}

.left-block>div>div {
    svg {
        display: none;
    }

    h5 {
        margin-bottom: 0.486vw;
        font-size: 1.125vw;
    }
}

.left-block>div>div>p {
    display: block;

    button {
        background-color: unset;
        color: white;
        border: none;
        font-weight: 200;
        font-size: 0.9375vw;
        opacity: 0.8;
        cursor: pointer;
    }

    a {
        text-decoration: none;
        color: white;
        font-weight: 200;
        font-size: 0.9375vw;
    }
}

.right-block {
    padding: 2.014vw 0 0.972vw 6.389vw;
    border-left: 0.069vw solid rgba(128, 128, 128, 0.671);
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.right-block>a>img {
    width: 100%;
    pointer-events: none;
}

.social>h5 {
    font-size: 1.25vw;
}

.social-icon {
    display: flex;
    justify-content: center;
    gap: 2.014vw;
}

.social-icon>a>svg {
    font-size: 2.014vw;
}

.social-icon>a {
    color: white;
}

@media (hover: hover) and (any-hover: hover) and (min-width: 430px) {
    .left-block>div>div>p>a:hover {
        font-weight: 300;
    }
}

@media screen and (max-width:430px) {
    .home-footer {
        flex-direction: column;
        padding: 4vw 0 0 0;
    }

    .flipped {
        transform: rotate(180deg);
    }

    .left-block>div {
        border-bottom: 0.069vw solid #b9b9b9;
    }

    .left-block {
        flex-direction: column;
        gap: 2.014vw;
        padding: 0 2.014vw;

        div {

            div {
                display: flex;
                justify-content: space-between;
                padding: 2vw;
                cursor: pointer;

                svg {
                    display: block;
                }

                h5 {
                    font-size: 4.028vw;
                    display: block;
                    margin: 0;
                }
            }

            div:nth-child(2) {
                display: none;
                padding: 0 2vw;

                p {
                    a {
                        font-size: 4vw;
                        font-weight: 400;
                        color: #b9b9b9;
                    }

                    button {
                        font-size: 4vw;
                        font-weight: 400;
                        color: #b9b9b9;
                    }
                }
            }
        }
    }

    .right-block {
        border-left: none;
        align-items: center;
        padding: 0;
        padding: 8vw;
        border-bottom: 0.069vw solid white;

        a {
            img {
                display: none;
            }
        }
    }

    .social>h5 {
        font-size: 4.028vw;
        margin: 0 0 5vw 0;
    }

    .social-icon {
        gap: 10vw;
        a>svg {
            font-size: 7.986vw;
        }
    }
}