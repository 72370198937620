.article-container,
.articles-header {
    overflow: hidden;
}

.articles-header {
    padding: 2.2vw 8vw;
    font-size: 1.5vw;
    font-weight: 600;
    color: #000;
    text-align: center;
    background-color: #DDEAFF;
    border-bottom: 0.069vw solid #91bbfe;

    a {
        text-decoration: none;
        color: #007bff;
    }
}

.articles-header>h1 {
    font-size: 2.125vw;
    margin-top: 0;
    margin-bottom: 0.347vw;
    color: #01183d;
}

.articles-header>p {
    margin-top: 0;
    font-size: 1.2vw;
    font-weight: 400;
    color: #000000;
    opacity: 0.8;
}

.articles-content {
    display: flex;
    overflow: hidden;
}

.articles-list {
    width: 30%;
    border-right: 0.069vw solid #cccccc;
    border-left: 0.069vw solid #cccccc;
}

.articles-list>h3 {
    border-bottom: 0.069vw solid #cccccc;
    margin: 0;
    font-size: 1.8vw;
    padding: 2.014vw 0 1.25vw 1.528vw;
}

.product-name {
    text-decoration: none;
}

.product-name>p {
    margin: 0;
    font-size: 1.2vw;
    font-weight: 400;
    padding: 0.556vw 1.528vw;
    border-bottom: 0.069vw solid #cccccc;
    color: #0e48a7;
}

@media (hover: hover) and (any-hover: hover) and (min-width: 430px) {

    .articles-header {
        a:hover {
            text-decoration: underline;
        }
    }

    .product-name {
        p:hover {
            transition: 0.2s;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 430px) {
    .article-container {
        overflow: initial;

        .articles-header {
            padding: 2.2vw 3vw;

            h1 {
                font-size: 7.6vw;
            }

            p {
                font-size: 5.2vw;

                a {
                    font-size: 5.2vw;
                }
            }
        }

        .articles-content {
            flex-direction: column-reverse;

            .articles-list {
                width: 100%;
                border: none;

                h3 {
                    padding: 2.014vw 0 1.25vw 1.528vw;
                    font-size: 5.1vw;
                }

                .product-name {
                    padding: 0;
                    margin: 0;

                    p {
                        font-size: 4.6vw;
                        margin: 2vw 0;
                    }
                }
            }
        }
    }
}