.cart {
    display: flex;
    width: 95%;
    flex-direction: column;
}

.product-button>a {
    text-decoration: none;
    color: var(--background-color);
}

.cart-title {
    color: var(--text-color);
    font-size: 1.319vw;
    width: inherit;
    font-weight: 600;
    margin-right: 0.625vw;
}

.cart-description {
    font-size: 0.972vw;
    margin: 0.139vw 0 0.972vw 0;
}

.cart-desc-line {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 0.486vw;
}

.top-line {
    width: 100%;
    border-bottom: 0.069vw solid #297fb968;
    margin: 0.625vw 0vw 1.25vw 0vw;
}

.license-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.625vw;

}

.license-type {
    margin: 0.347vw 0;
    cursor: pointer;
    width: 75%;
    font-size: 1.042vw;
    font-weight: 600;
    display: flex;
    border-radius: 0.347vw;
    padding: 0.139vw 0.347vw;
    justify-content: space-between;
    background-color: white;
    border: 0.069vw solid #000000ad;
}

.type {
    margin: 0;
    font-size: 1.181vw;
}

.license-container>select {
    border-color: #000000ad;
    border-radius: 0.347vw;
    width: 40%;
    opacity: 0.9;
    height: 1.597vw;
    padding: 0 0.625vw;
    outline: none;
    font-size: 1.181vw;
    font-weight: 600;
    color: var(--text-color);
}

.quantity-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.042vw;
    font-weight: 600;
}

.quantity-container>p {
    margin: 0.347vw 0 0 0;
    font-size: 1.181vw;
}

.credit-quantity-container {
    width: 145%;
}

.counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.3vw;
}

.quantity-price-counter {
    display: inline-flex;
    gap: 6.319vw;
    padding-right: 2.153vw;
}

.operation {
    background-color: white;
    border: 0.069vw solid #000000ad;
    border-radius: 0.347vw;
    font-size: 1.181vw;
    font-weight: 600;
    cursor: pointer;
    width: 1.7vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.count {
    border: 0.069vw solid #000000ad;
    background-color: white;
    text-align: center;
    width: 2.222vw;
    height: 1.35vw;
    border-radius: 0.347vw;
    font-size: 1.042vw;
    font-weight: 600;
}

.display-total {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 0.972vw;
}

.total-amount-quantity {
    display: inline-flex;
    gap: 1.958vw;
}


.total {
    color: #2980b9;
}

.total>b {
    font-size: 1.181vw;
    font-weight: 900;
}

.amount {
    color: rgb(49, 167, 26);
    font-size: 1.319vw;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.amount>span {
    color: black;
    font-size: 1.181vw;
    font-weight: 600;
}

.quantity {
    font-size: 1.181vw;
    font-weight: 600;
    justify-content: end;
    display: grid;
    grid-template-columns: 19.7vw 6.428vw 6.056vw;
}

.payment-type {
    font-size: 1.181vw;
    font-weight: 600;
    justify-content: flex-end;
    display: flex;
}

.item-price-quantity {
    display: grid;
    grid-template-columns: 14.019vw 10.217vw auto;
    font-size: 1.181vw;
    font-weight: 600;
    margin-bottom: 0.347vw;
}

.product-prices {
    text-align: end;
}

.product-quantities {
    text-align: center;
    padding-left: 1.914vw;
}

.display-pricing>span {
    font-size: 1.042vw;
    display: flex;
    justify-content: flex-end;
    margin: 0.347vw 0 0.972vw 0;
}

.quality {
    color: var(--text-color);
    width: inherit;
    text-align: center;
    margin-top: 1.667vw;
    font-size: 1.25vw;
}

.cart-button {
    color: var(--background-color);
    background-color: var(--primary-color);
    border: none;
    font-size: 1.458vw;
    height: 3.264vw;
    border-radius: 0.625vw;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
        transition: 0.3s;
    }
}

.cart-button>a {
    text-decoration: none;
    color: var(--background-color);
}

.immediate {
    margin-top: 0.833vw;
    width: 100%;
    text-align: center;
    font-size: 1.25vw;
    color: #4d4d4d;
    font-weight: 600;
}

@media only screen and (max-width: 430px) {
    .cart {
        width: 100%;
    }

    .cart-title {
        font-size: 5vw;
    }

    .cart-description {
        font-size: 4.375vw;
    }

    .quantity {
        font-size: 4.8vw;
        grid-template-columns: 19.028vw 20.486vw;
    }

    .license-container>select {
        width: 45%;
        font-size: 4.6vw;
        height: 6.556vw;
    }

    .license-type {
        font-size: 4.028vw;
        width: 70%;
        padding: 0.347vw 1.319vw;
        border-radius: 1.319vw;

        .type {
            font-size: 4.028vw;
        }
    }

    .quantity-container {
        margin: 2.014vw 0;
    }

    .quantity-container>p {
        font-size: 4.84vw;
    }

    .item-price-quantity {
        display: grid;
        grid-template-columns: 40vw 25vw auto;
        font-size: 4.167vw;
        font-weight: 600;
        margin: 0.972vw 0;
    }

    .counter {
        gap: 1vw;
    }

    .operation {
        font-size: 6.028vw;
        width: 6.51vw;
        border-radius: 0.972vw;
    }

    .count {
        width: 13.48vw;
        height: 6.97vw;
        font-size: 6.028vw;
        border-radius: 0vw;
        border-radius: 0.972vw;
    }

    .total>b {
        font-size: 6.08vw;
    }

    .amount {
        font-size: 5vw;

        span {
            font-size: 6.08vw;
        }
    }

    .payment-type {
        font-size: 4.722vw;
    }

    .display-total {
        padding-bottom: 1vw;
    }

    .display-pricing>span {
        font-size: 5vw;
    }

    .cart-button {
        font-size: 5.51vw;
        height: 10.014vw;
        border-radius: 2.639vw;
    }

    .mobile-cart-description {
        font-size: 4.375vw;
        font-weight: 600;
        margin: 0 0 2vw 0;
    }

    .mail-info {
        font-size: 4.375vw;
        margin: 0 0 3vw 0;
    }

    #email {
        font-size: 4.51vw;
        height: 9.014vw;
        margin: 0 0 4vw 0;
        outline: none;
        border: 0.069vw solid #000000ad;
        border-radius: 2.639vw;
        padding-left: 2.014vw;
    }

    .choosed-products {
        padding-left: 2.014vw;
    }

    .product-quantities {
        padding-left: 5.972vw;
    }

    .quality {
        font-size: 4.097vw;
        margin-top: 2.014vw;
    }

    .immediate {
        font-size: 3.997vw;
        margin: 2.014vw 0;
    }

    .top-line {
        margin: 2.014vw 0 3vw 0;
    }
}