.newsletter {
    border-radius: 1.389vw;
    margin-bottom: 1.319vw;
    border: 0.139vw #aba9a9bf solid;
}

.sidecardheader {
    font-weight: 600;
    font-size: 1.389vw;
    background-color: #d5eafd;
    color: black;
    padding: 1.25vw;
    border-top-right-radius: 1.25vw;
    border-bottom: 0.069vw #aba9a9bf solid;
    border-top-left-radius: 1.25vw;
}

.newstext {
    font-size: 1.042vw;
    padding: 1.597vw 0.625vw;
}

.form-group {
    text-align: center;
    margin: 0.486vw;
    display: flex;
    flex-direction: column;
}

.form-group>input {
    border-radius: 0.278vw;
    border: 0.069vw #aba9a9bf solid;
    margin-right: 0.7761966364812419vh;
    padding-left: 1.8111254851228977vh;
    height: 1.736vw;
    font-size: 1.042vw;
}

.subscribe {
    width: 100%;
    height: 2.014vw;
    outline: none;
    border: none;
    color: var(--background-color);
    background-color: #000000;
    border-color: var(--text-color);
    margin-top: 0.972vw;
    font-size: 1.042vw;
    border-radius: 0.347vw;
    margin-right: 0.347vw;
    margin-bottom: 0.972vw;
    box-shadow: none;

    &:hover {
        opacity: 0.9;
    }
}

@media only screen and (max-width: 430px) {
    .newsletter {
        border-radius: 4.375vw;
        margin: 4.375vw 0;
    }

    .sidecardheader {
        font-size: 5.6vw;
        padding: 4.236vw;
        border-top-right-radius: 5vw;
        border-top-left-radius: 5vw;
    }

    .newstext {
        font-size: 4.375vw;
        padding: 4.375vw 3.264vw;
    }

    .form-group {
        margin: 2.5vw;
    }

    .form-group>input {
        border-radius: 7vw;
        border: 0.347vw #aba9a9bf solid;
        height: 10vw;
        font-size: 4.375vw;
    }

    .subscribe {
        height: 10vw;
        margin-top: 4vw;
        font-size: 4.375vw;
        border-radius: 7vw;
        margin-right: 3.75vw;
        margin-bottom: 7.5vw;
        cursor: pointer;
    }

}