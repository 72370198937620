.policy-header {
    background-color: black;
    border-top: 0.069vw solid rgba(128, 128, 128, 0.671);

    h1 {
        color: white;
        margin: 0;
        text-align: center;
        padding: 5.972vw 0;
        font-size: 2.222vw;
    }
}

.privacy-policy {
    max-width: 75vw;
    margin: 0 auto;
    padding: 0 1.25vw;
}

.privacy-policy-about {
    margin: 2.5vw 0 2.5vw 0;

    b {
        font-size: 1.111vw;
    }

    p {
        font-size: 0.972vw;
    }
}

.privacy-policy-content {

    ol>li,
    ul>li {
        b {
            margin-top: 0.486vw;
            display: flex;
        }

        font-size: 0.972vw;
        line-height: 1.597vw;

        a {
            text-decoration: none;
            color: #8d91ff;
        }
    }
}

.updteded-date {
    text-align: end;
}

@media screen and (max-width: 430px) {
    .privacy-policy-page {
        .policy-header {
            h1 {
                font-size: 5.122vw;
                padding: 13.972vw 0;
            }
        }

        .privacy-policy {
            max-width: none;
            padding: 0 5.25vw;

            .privacy-policy-about {
                margin: 5.25vw 0 5.25vw 0;

                b {
                    font-size: 4.125vw;
                }

                p {
                    font-size: 3.5vw;
                }
            }

            .privacy-policy-content {
                ol {
                    padding: 0 0 0 2.5vw;

                    li {
                        font-size: 3.5vw;
                        line-height: 4.125vw;

                        b {
                            margin-top: 1.25vw;
                        }

                        p {
                            font-size: 3.5vw;

                            a:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .updteded-date {
                font-size: 3.5vw;
            }
        }
    }

}