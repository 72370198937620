.technical-header {
    padding: 1.3vw;
    font-size: 1.25vw;
    font-weight: 600;
    color: #000;
    text-align: center;
    background-color: #DDEAFF;
    border-bottom: 0.069vw solid #91bbfe;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.technical-header>h1 {
    font-size: 2.125vw;
    margin-top: 0;
    margin-bottom: 0.347vw;
    color: #01183d;
}

.technical-header>p {
    margin-top: 0;
    margin-bottom: 2.153vw;
    font-size: 1.2vw;
    font-weight: 400;
}

.support-type {
    display: flex;
    justify-content: space-around;
}

.support-type>button {
    margin: 0.972vw auto;
    height: 6.25vw;
    width: 12.5vw;
    border: 0.208vw solid #0089bf;
    border-radius: 0.625vw;
    color: #0089bf;
    font-size: 1.0625vw;
    background-color: white;
}

.support-form {
    max-width: 98vw;
    padding: 0 1.25vw;
    margin: 0 auto;
}

.support-form>p {
    font-size: 1.0625vw;
    font-weight: 600;
    margin-bottom: 0.625vw;

}

.search-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-box>input {
    width: 35%;
    height: 2.014vw;
    border: 0.069vw solid #91bbfe;
    border-radius: 1.597vw 0 0 1.597vw;
    border-left: none;
    padding-left: 1.597vw;
    font-size: 1.25vw;
    font-weight: 400;
    outline: none;
}

.search-elements {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.069vw solid #91bbfe;
    border-radius: 0 1.597vw 1.597vw 0;
    border-right: none;
    background-color: white;
}

.search-elements>svg:nth-child(1) {
    padding-left: 0.625vw;
    margin-right: 0.625vw;
}

.search-elements>svg {
    width: 2.222vw;
    height: 2.153vw;
    margin-right: 0.625vw;
    fill: rgb(0, 137, 191);
    cursor: pointer;
}

.previous-results {
    position: absolute;
    top: 10.3vw;
    background-color: white;
    border-radius: 1vw;
    width: 41%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 0.347vw 0;
}

.search-suggestion {
    display: flex;
    width: 100%;

    p {
        margin: 0;
        text-align: start;
        padding: 0.5vw 1vw;
        color: #0089bf;
    }
}

.results {
    width: 96%;
    display: grid;
    grid-template-columns: 95% 5%;
    border-top: 0.069vw solid #80808061;

    .close {
        height: 2.3vw;
        width: 45%;
        padding-left: 0.6vw;
    }

    .search-results {
        display: grid;
        grid-template-columns: 5% 95%;
        background: none;
        border: none;
        padding: 0;

        p {
            margin: 0.5vw 0;
            text-align: start;
            font-size: 1.15vw;
            font-weight: 400;
            height: 1.2vw;
        }

        .resent {
            height: 2.3vw;
            width: 70%;
            padding: 0;
        }

    }
}

.suggestions {
    align-self: stretch;

    div {
        display: grid;
        grid-template-columns: 5% 95%;
        align-items: center;
        column-gap: 0.625vw;
        padding: 0.5vw 1vw;
        border-top: 0.069vw solid #80808061;

        p {
            margin: 0;
            text-align: start;

            a {
                text-decoration: none;
                color: black;
                font-size: 0.85em;
                text-align: start;
                font-weight: 400;
            }
        }
    }
}

.form-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1.25vw;
}


.form-content>label {
    font-size: 1.125vw;
    font-weight: 600;
    margin-bottom: 0.625vw;
}

.help {
    display: flex;
    justify-content: flex-start;
    gap: 3.542vw;
    align-items: center;
}

.help>select {
    height: 2.5vw;
    border: 0.069vw solid;
    border-radius: 0.625vw;
    padding-left: 0.625vw;
    font-size: 1.25vw;
    font-weight: 400;
    outline: none;
}

.help>select>option:checked {
    font-weight: bold;
}

.help>input {
    height: 2.5vw;
    border: 0.069vw solid;
    border-radius: 0.625vw;
    padding-left: 0.625vw;
    font-size: 1.25vw;
    font-weight: 400;
    outline: none;
}

.form-content>input {
    height: 2.5vw;
    border: 0.069vw solid;
    border-radius: 0.625vw;
    padding-left: 0.625vw;
    font-size: 1.25vw;
    font-weight: 400;
    outline: none;
}

.form-content>select {
    width: 100%;
    height: 2.5vw;
    border: 0.069vw solid;
    border-radius: 0.625vw;
    padding-left: 0.625vw;
    font-size: 1.25vw;
    font-weight: 400;
    outline: none;
}

.form-content>select>option:checked {
    font-weight: bold;
}

.form-content>p {
    font-size: 1.25vw;
    font-weight: 600;
    margin-bottom: 0.625vw;
}

.connect {
    margin-bottom: 2.014vw;
    padding: 0.625vw;
    border-radius: 0.625vw;
    box-shadow: 0 0 0.347vw 0;
}

.message {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-support {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-support>input {
    width: 48%;
    height: 2.5vw;
    border: 0.069vw solid;
    border-radius: 0.625vw;
    padding-left: 0.625vw;
    font-size: 1.25vw;
    font-weight: 400;
    outline: none;
}

.connect>p,
.contact-support>p {
    font-size: 1.25vw;
    font-weight: 600;
}

.connect>p>a,
.contact-support>p>a {
    text-decoration: none;
}

.connect>button,
.contact-support>button {
    width: 28%;
    height: 2.5vw;
    border: none;
    border-radius: 0.625vw;
    font-size: 1.25vw;
    outline: none;
    background-color: #0089bf;
    color: white;
    cursor: pointer;
    padding: 0;
}

.connect>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.connect>div>div {
    display: flex;
    justify-content: space-between;
    gap: 0.833vw;
}

.connect>div>div>button {
    color: #0089bf;
    border: 0.069vw solid #0089bf;
    background-color: white;
    border-radius: 0.347vw;
    width: 6.181vw;
    height: 2.5vw;
    transition: 0.5s;
    cursor: pointer;
    font-size: 1.111vw;
}

.request-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0.347vw);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 12;
}

.request {
    width: 35vw;
    height: 35.972vw;
    background-color: white;
    border-radius: 0.625vw;
    padding: 1.25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.request>h3 {
    text-align: center;
    font-size: 1.528vw;
    font-weight: 600;
    margin-bottom: 0.972vw;
    color: #00418f;
}

input::placeholder {
    color: #b8b8b8;
}

textarea::placeholder {
    color: #b8b8b8;
}

.request-form {
    width: 95%;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto auto auto;
    gap: 0.625vw;
    column-gap: 1.597vw;
}

.request-form>label {
    font-size: 1.111vw;
    font-weight: bold;
    margin-top: 0.903vw;
}

.request-form>div>input {
    padding: 0.486vw 0.764vw;
    border: 0.069vw solid #8a7575;
    border-radius: 0.347vw;
    font-size: 1.111vw;
    outline: none;
}

.request-form>div>select {
    color: #8a7575;
    padding: 0.486vw 0.764vw;
    border: 0.069vw solid #8a7575;
    border-radius: 0.347vw;
    font-size: 1.111vw;
    overflow: auto;
    outline: none;
}

.request-form>div>select>option {
    color: black;
}

.request-form>div>select>option:checked {
    font-weight: bold;
}

.request-form>div>p {
    position: absolute;
    color: red;
    font-size: 0.833vw;
    margin: 0;
}

.request-form-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 0.972vw;
    position: relative;
    top: -20%;
    width: 95%;
    gap: 0.972vw;
}

.request-form-buttons>button {
    cursor: pointer;
    padding: 0.625vw 1.319vw;
    border: 0.208vw solid #0089bf;
    font-size: 0.903vw;
    text-transform: uppercase;
    font-weight: 600;
    transition: .3s;
    border-radius: 0.486vw;
}

.submit-form {
    background-color: #0089bf;
    color: #ffffff;
}

.close-form {
    background-color: #ffffff;
    color: #0089bf;
}

.message-box {
    margin-top: 0.972vw;
    resize: none;
    display: flex;
    flex-direction: column;
}

.message-box-content {
    display: flex;
    justify-content: center;
    gap: 25.972vw;

}

.message-box-content>p {
    margin: 0;
}

.message-box-content>label {
    font-size: 1.111vw;
    font-weight: bold;
}

.text-message {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50%;
}

.text-message>textarea {
    font-family: 'Inter', sans-serif;
    border: 0.069vw solid #8a7575;
    border-radius: 0.625vw;
    padding-left: 0.625vw;
    font-size: 1.25vw;
    font-weight: 400;
    outline: none;
    resize: none;
    width: 100%;
    margin-top: 0.347vw;
}

.text-message>p {
    position: absolute;
    bottom: -1.347vw;
    color: red;
    font-size: 0.833vw;
    margin: 0;
    left: 0.694vw;
}

.validate {
    display: none;
}

@media (hover: hover) and (any-hover: hover) and (min-width: 430px) {

    .results:hover {
        background-color: #f1f1f1d2;

        button {
            p {
                color: #004599;
            }
        }
    }

    .suggestions {
        div:hover {
            background-color: #f1f1f1d2;

            p {

                a {
                    color: #004599;
                }
            }
        }

        div:last-child:hover {
            border-bottom-left-radius: 1vw;
            border-bottom-right-radius: 1vw;
        }
    }

    .connect>p>a:hover,
    .contact-support>p>a:hover {
        text-decoration: underline;
    }

    .support-type>button:hover {
        border-color: #87d6e5
    }

    .connect>button:hover,
    .contact-support>button:hover {
        transition: 0.1s;
        transform: scale(1.04, 1.04);
        -webkit-transform: scale(1.04, 1.04);
    }

    .connect>div>div>button:hover {
        background-color: rgb(0 137 191 / 20%);
    }

    .submit-form:hover {
        border: 0.139vw solid #004599;
        background-color: #004599;
        box-shadow: 0 0.347vw 0.625vw rgba(0, 0, 0, 0.35);
    }

    .close-form:hover {
        border: 0.139vw solid #e75d5d;
        color: #ffffff;
        background-color: #e75d5d;
        box-shadow: 0 0.347vw 0.625vw rgba(0, 0, 0, 0.35);
    }
}

@media screen and (max-width:430px) {
    .technical-header {
        padding: 6vw 0 9vw 0;

        h1 {
            font-size: 7.4vw;
        }

        p {
            font-size: 4.1vw;
            margin-bottom: 5.153vw;
        }

        .search-box {
            input {
                font-size: 3.8vw;
                width: 60%;
                height: 7vw;
                border-radius: 3.597vw 0 0 3.597vw;
            }

            .search-elements {
                width: 15%;
                border-radius: 0 3.597vw 3.597vw 0;

                svg {
                    height: 7.3vw;
                    width: 5.8vw;
                    padding: unset;
                    margin: unset;
                }
            }
        }
    }

    .support-form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 3.25vw;

        p {
            font-size: 4.1vw;
            text-align: center;
        }

        .support-type {
            flex-direction: column;

            button {
                height: 23.25vw;
                width: 54.5vw;
                font-size: 4.2625vw;
                border-radius: 3.597vw;
                border: 1vw solid #0089bf;
            }
        }

        .form-content {
            label {
                font-size: 4.1vw;
                margin: 4.597vw 0 2.597vw 0;
            }

            .help {
                flex-direction: column;

                #selectApp {
                    font-size: 3.8vw;
                    height: 10vw;
                    width: 100%;
                    border-radius: 1.597vw;
                    padding: 1.625vw;
                }

                input {
                    font-size: 4.1vw;
                    height: 6vw;
                    width: 97%;
                    border-radius: 1.597vw;
                    padding: 1.625vw;
                }

            }

            #issues {
                font-size: 4.1vw;
                height: 10vw;
                width: 100%;
                border-radius: 1.597vw;
                padding: 1.625vw;
            }

            #issue {
                font-size: 4.1vw;
                height: 10vw;
                width: 100%;
                border-radius: 1.597vw;
                padding: 1.625vw;
            }

            #solution {
                font-size: 4.1vw;
                height: 6vw;
                width: 97%;
                border-radius: 1.597vw;
                padding: 1.625vw;
            }

            p {
                font-size: 4.1vw;
                margin-bottom: 3.597vw;
            }

            .connect {
                margin-bottom: 6.5vw;
                padding: 3.125vw;
                border-radius: 3.597vw;
                box-shadow: 0 0 1.597vw 0;

                p {
                    font-size: 4.1vw;
                }

                button {
                    height: 10vw;
                    font-size: 4.1vw;
                    width: 32vw;
                    border-radius: 2.597vw;
                }

                .contact-support {
                    flex-direction: column;

                    p {
                        font-size: 4.1vw;
                    }

                    button {
                        height: 10vw;
                        font-size: 4.1vw;
                        width: 42vw;
                        border-radius: 2.597vw;
                    }
                }

                div {
                    flex-direction: column;

                    p {
                        font-size: 3.8vw;
                    }

                    div {
                        flex-direction: row;

                        button {
                            font-size: 3.8vw;
                            width: 20vw;
                            height: 8vw;
                        }
                    }
                }
            }

            .message {
                flex-direction: column;
                gap: 0vw;
            }
        }
    }

    .request-box {
        .request {
            width: 85%;
            height: 90%;
            border-radius: 3.597vw;
            overflow-y: scroll;

            h3 {
                font-size: 4.5vw;
                margin-bottom: 4.1vw;
            }

            .request-form label[for="email"],
            .request-form div:nth-child(7) {
                order: 1;
            }

            .request-form label[for="phone"],
            .request-form .react-tel-input {
                order: 2;
            }

            .request-form {
                display: flex;
                flex-direction: column;
                gap: 0;

                label {
                    font-size: 4.1vw;
                    margin-top: 3.997vw;
                }

                div {
                    display: flex;
                    flex-direction: column;

                    input {
                        padding: 1.5vw 1.5vw;
                        font-size: 4.1vw;
                        border-radius: 2vw;
                        margin-top: 3.6vw;
                        width: 96%;
                    }

                    select {
                        padding: 1.5vw 0.5vw;
                        font-size: 4.1vw;
                        border-radius: 2vw;
                        width: 100%;
                        margin-top: 3vw;
                    }

                    p {
                        font-size: 3.2vw;
                        margin-top: 0.5vw;
                        position: relative;
                    }
                }

                .react-tel-input {
                    margin-top: 3vw;

                    .special-label {
                        display: none;
                    }

                    .form-control {
                        padding-left: 14%;
                        font-size: 4.5vw;
                        height: 8vw;
                        width: 100%;
                    }
                }
            }

            .message-box {
                margin-top: 3.972vw;
                width: 95%;

                .message-box-content {
                    justify-content: space-between;
                    gap: 0;
                    padding-top: 2vw;

                    label {
                        font-size: 4.1vw;
                    }

                    p {
                        font-size: 3.8vw;
                    }
                }

                .text-message {
                    height: unset;

                    textarea {
                        font-size: 4.1vw;
                        margin-top: 2.597vw;
                        border-radius: 2vw;
                        padding-left: 1.625vw;
                    }

                    p {
                        bottom: -4vw;
                        font-size: 2.8vw;
                        left: 0;
                    }
                }
            }

            .request-form-buttons {
                margin-top: 16vw;
                top: -4%;

                button {
                    padding: 2.597vw 5.5vw;
                    font-size: 3.8vw;
                    border-radius: 2.597vw;
                }
            }
        }
    }
}