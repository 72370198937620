.help-page {
    max-width: 87.5vw;
    /* margin: 0 auto; */
    padding: 0 1.25vw;
}

.product-name-title {
    a {
        color: #c97c00;
        text-decoration: none;

        h1 {
            font-size: 1.875vw;
            margin: 0.972vw 0;
        }
    }
}

.help-heading {
    h2 {
        color: #005ccb;
        font-size: 1.389vw;
    }
}

.version-number {
    border-top: 0.069vw solid black;
    font-size: 1.111vw;
}

.help-content {
    font-size: 1.042vw;
    line-height: 1.528vw;
    margin-bottom: 2.014vw;

    ul>li {
        margin-bottom: 0.972vw;

        p {
            font-size: 1.125vw;
        }

        a {
            color: #005ccb;
            text-decoration: none;
        }
    }
}

@media (hover: hover) and (any-hover: hover) and (min-width: 430px) {
    .product-name-title {
        a:hover {
            text-decoration: underline;
        }
    }

    .help-content {
        ul>li {
            a:hover {
                text-decoration: underline;
            }
        }
    }
}

@media screen and (max-width: 430px) {
    .help-page {
        max-width: unset;
    }

    .product-name-title {
        a {
            h1 {
                font-size: 5.875vw;
                margin: 0.972vw 0;
            }
        }
    }

    .help-heading {
        h2 {
            margin: 1vw 0 2vw 0;
            font-size: 4.389vw;
        }
    }

    .version-number {
        border-top: 0.069vw solid black;
        font-size: 4.111vw;
    }

    .help-content {
        line-height: 5.528vw;
        margin-bottom: 2.014vw;

        ul {
            padding-left: 6vw;
            li {
                margin-bottom: 0.972vw;

                p {
                    font-size: 4.125vw;
                }
            }
        }
    }
}