.product {
    display: flex;
    justify-content: center;
    width: -webkit-fill-available;
    padding: 0.625vw 0vw 1.25vw 0vw;
}

@media screen and (max-width: 430px) {
    .product {
        align-items: center;
        flex-flow: column;
    }
}

@media screen and (max-width: 1200px) and (min-width: 900px) {
    .product {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            "video-container cart"
            "imp-feature imp-feature";
        grid-gap: 0.625vw;
    }

    .video-container {
        grid-area: video-container;
    }

    .cart {
        grid-area: cart;
    }

    .imp-feature {
        grid-area: imp-feature;
    }

    .video {
        width: 44.306vw;
    }
}