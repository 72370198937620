.productPage {
    max-width: 104.167vw;
    margin: 0 auto;
    padding: 0 1.589vw;
}

@media screen and (max-width: 430px) {
    .productPage {
        padding: 0 2.65vw;
    }
}