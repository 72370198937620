.media-header {
    padding: 2.9vw;
    font-size: 1.25vw;
    font-weight: 600;
    color: #000;
    text-align: center;
    background-color: #DDEAFF;
    border-bottom: 0.069vw solid #91bbfe;
}

.media-header>h1 {
    font-size: 2.25vw;
    margin-top: 0;
    margin-bottom: 0.347vw;
    color: #01183d;
}

.media-header>p {
    margin-top: 0;
    font-size: 1.25vw;
    font-weight: 400;
    color: #000000;
    opacity: 0.8;
}

.media {
    max-width: 77.5vw;
    margin: 1.528vw auto;
    padding: 0 1.25vw;
    display: flex;
    flex-direction: column;
}

.media>hr {
    margin: 0.7vw;
    border: 0.069vw solid #cccccc;

}

.media-block {
    display: flex;
    justify-content: space-between;
    gap: 2.014vw;
}

.inner-media {
    display: flex;
    flex-direction: column;
}

.product-coverage {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;

    img {
        width: 2.014vw;
        margin-right: 0.972vw;
        pointer-events: none;
    }

    h2 {
        font-size: 1.389vw;
        margin: 0;

        a {
            text-decoration: none;
        }
    }

    &:hover {
        text-decoration: underline;
    }
}

.media-coverage {
    a {
        cursor: pointer;
        align-items: center;
        gap: 0.972vw;
        text-decoration: none;
        color: rgb(61, 61, 61);

        &:hover {
            text-decoration: underline;
            color: #0e48a7;
        }

        h3 {
            margin-bottom: 0;
            font-size: 1.25vw;
        }

        p {
            margin-top: 0.208vw;
            font-size: 1.25vw;
            color: #000000;
            opacity: 0.8;
        }
    }
}