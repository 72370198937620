.free-download {
    width: 94%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 3.75vw;
    padding: 2.014vw;
    margin: 2.153vw 0.764vw;
    background-color: #f8f8f8;
    border: 0.069vw solid #bbbbbbd0;
    border-radius: 1.25vw;
    box-shadow: 0vw 0vw 0.347vw 0vw rgb(163 160 160 / 48%);
}

.download-icon {
    font-size: 4.028vw;
    width: 5vw;
    filter: brightness(0) saturate(100%) invert(38%) sepia(70%) saturate(5450%) hue-rotate(201deg) brightness(104%) contrast(105%);
}

.content-top {
    color: black;
    text-align: center;
}

.heading {
    font-size: 1.319vw;
    min-width: 16.111vw
}

.content-bottom {
    font-size: 1.25vw;
    font-weight: 600;
    margin-top: 0.625vw;
    margin-bottom: 0;
}

.free-download-button{
    color: var(--background-color);
    background-color: var(--primary-color);
    border: none;
    border-radius: 0.625vw;
    font-size: 1.667vw;
    width: 20vw;
    display: flex;
    height: 3.056vw;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover{
        opacity: 0.9;
    }
}

.free-download-button>a{
    font-size: 1.389vw;
    text-decoration: none;
    color: var(--background-color);

}

@media screen and (max-width: 430px) {
    .free-download {
        display: none;
    }

}