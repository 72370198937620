.product-title{
    margin: 0 0 0.3125vw 0;
}

.title {
    color: var(--product-title);
    font-size: 1.875vw;
    font-weight: 600;
    margin: 1vw 0 0.5vw 0;
}

.sub-title {
    color: var(--text-color);
    margin: 0.625vw 0;
    font-weight: 600;
    font-size:  1.125vw;
}

@media only screen and (max-width: 430px) {
    .title {
        font-size: 7.6vw;
    }
    .sub-title {
        font-size: 4.6vw;
        margin: 3vw 0;
    }
    
}