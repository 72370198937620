.categories {
    padding: 0 2.775vw 2vw 2.775vw;
}

.categories>p {
    font-size: 1.375vw;
}

.product-categories {
    display: flex;
    justify-content: space-evenly;
    gap: 1.25vw;
    margin: 1.25vw 0;

}

.category-type {
    height: 8vw;
    width: 10vw;
    background: rgb(223, 226, 255);
    border-radius: 1.5625vw;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.625vw;
    font-size: 0.9375vw;
    padding: 0.625vw;

}

.category-type:hover {
    cursor: pointer;
    background: #b8c4fc;
    box-shadow: 0 0 0.625vw 0 black;
}

.category-type>a>svg {
    width: 3vw;
    height: 3vw;
    padding: 0.625vw 0 0 0;
}

.category-type>a>h4 {
    font-size: 0.9375vw;
    padding-top: 0.625vw;
    margin: 0;
}

.images-wrap>div>div>a {
    display: block;
    font-size: 0;
    line-height: 0;

    img {
        width: 100%;
        object-fit: contain;
    }
}


@media screen and (max-width:430px) {
    .categories {
        display: none;
    }

    /* .categories{
        text-align: center;
    }

    .categories>p{
        font-size: 5vw;
        margin: 2vw 0;
    }

    .product-categories {
        display: grid;
        grid-template-columns: auto auto;
    }

    .category-type{
        width: 40vw;
        height: 30vw;
        border-radius: 5vw;
        font-size: 5vw;
        padding: 5vw;
    }

    .category-type>a>svg {
        font-size: 12vw;
        height: 12vw;
    }

    .category-type>a>h4{
        font-size: 5vw;
        padding-top: 5vw;
        margin: 0;
    } */
}