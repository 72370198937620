.aboutus-header {
    padding: 1.1vw 6vw;
    font-size: 1.25vw;
    font-weight: 600;
    color: #000;
    text-align: center;
    background-color: #DDEAFF;
    border-bottom: 0.069vw solid #91bbfe;
}

.aboutus-header>h1 {
    font-size: 2.125vw;
    margin-top: 0;
    margin-bottom: 0.347vw;
    color: #01183d;
}

.aboutus-header>p {
    margin-top: 0;
    line-height: 1.736vw;
    font-size: 1.2vw;
    font-weight: 400;
    color: #000000;
    opacity: 0.8;
}

.aboutus-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.389vw auto 0 auto;
    max-width: 111.111vw;
    padding: 0 1.25vw;
}

.aboutus-container>button {
    padding: 0.625vw 2.5vw;
    font-size: 1.25vw;
    background-color: #fff;
    color: #004598;
    border-color: #004598;
    border-radius: 2.014vw;
    cursor: pointer;

    &:hover {
        background-color: #dcedff;
        border-color: #01183d;
    }
}

.aboutus-container>p {
    font-size: 2vw;
    margin-bottom: 0;
}

.deskshare-info {
    display: flex;
    align-items: center;
    margin: 2.014vw 4vw;
    border: 0.069vw solid black;
}

.deskshare-icon {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    height: 17.014vw;
}

.deskshare-about {
    padding: 2.014vw;
    width: 50%;
}

.deskshare-about>p {
    font-size: 1.1875vw;
    line-height: 1.736vw;
    margin: 0;
}

.product-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 0.069vw solid #65656566;
    border-radius: 0.347vw;
    padding: 0 0.833vw;
    width: 26.667vw;
    height: 8.5vw;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 50%);
    }
}

.product-box>p {
    font-size: 1vw;
    margin: 0.972vw 0;
    color: #000;
    opacity: 0.8;
}

.product-icon-name {
    display: flex;
    align-items: center;
    margin-top: 0.972vw;
}

.product-icon-name>img {
    width: 2.5vw;
    margin-right: 2.014vw;
    pointer-events: none;
}

.product-icon-name>h4 {
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.375vw;
    margin: 0;
    font-weight: 400;
    color: #000;

    &:hover {
        text-decoration: underline;
    }
}

.product-icon-name>p {
    font-size: 0.972vw;
    margin: 0;
    color: #000;
    opacity: 0.8;
}

.product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0.972vw;
    margin: 0.972vw 0;

    a {
        /* width: 16.1vw; */
        text-decoration: none;
    }
}

.media-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1vw 0;
    border: 0.069vw solid #654532;
    border-radius: 0.972vw;
    width: 35%;

    a {
        text-decoration: none;
    }
}

.media-container>a>p {
    margin: 0;
}

.media-title {
    padding: 0.972vw;
    background-color: #004598;
    color: #fff;
    font-size: 1.25vw;
    border-top-left-radius: 0.972vw;
    border-top-right-radius: 0.972vw;
    font-weight: 600;
    text-align: center;
}

.media-description {
    padding: 2.014vw;
    font-size: 0.9375vw;
    text-align: center;
    color: #000;
    opacity: 0.8;
}

@media screen and (max-width:430px) {
    .aboutus-header {
        padding: 3vw 2vw;

        h1 {
            font-size: 5.1vw;
        }

        p {
            font-size: 4.1vw;
            line-height: 4.736vw;
        }
    }

    .aboutus-container {
        button {
            padding: 2.5vw 5vw;
            font-size: 5vw;
            border-radius: 5vw;
            margin: 4vw 1vw;
        }

        .what-we-do {
            font-size: 8vw;
            margin-top: 9vw;
        }

        .solution {
            font-size: 8vw;
            margin-top: 9vw;
        }

        .deskshare-info {
            border: unset;
            display: block;

            .deskshare-icon {
                display: none;
            }

            .deskshare-about {
                width: 100%;
                padding: 0;

                p {
                    font-size: 4vw;
                    line-height: 5.736vw;
                }
            }
        }

        .product-cards {
            width: 98%;
            height: 50%;

            .product-container {
                gap: 2vw;

                a {
                    width: 97%;
                    margin: 2vw 0;

                    .product-box {
                        width: 98%;
                        height: 100%;
                        padding: 2vw 2vw;

                        .product-icon-name {
                            margin-top: 2vw;

                            img {
                                width: 8vw;
                                margin-right: 4vw;
                            }

                            h4 {
                                font-size: 6vw;
                            }

                            p {
                                font-size: 4vw;
                            }
                        }

                        p {
                            font-size: 3.5vw;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .press {
            font-size: 8vw;
            margin-top: 20vw;
        }

        .media-container {
            width: 98%;

            a {
                .media-title {
                    font-size: 4vw;
                }

                .media-description {
                    font-size: 3.5vw;
                }
            }
        }
    }


}