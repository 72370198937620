.detailed-article {
    display: flex;
    max-width: 111.111vw;
}

.more-article {
    width: 25%;
    border-left: 0.069vw solid #e0e0e0;
    border-right: 0.069vw solid #e0e0e0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 9.314359637774903vh - 4.915912031047865vh);
    scrollbar-width: thin;
    margin: 0;

    a {
        text-decoration: none;
    }

    p {
        border-bottom: 0.069vw solid #e0e0e0;
        margin: 0;
        padding: 1.25vw;
        font-size: 1.125vw;
        cursor: pointer;
        color: #000000;

        &:hover {
            background-color: #DDEAFF;
            border: 0.069vw solid #91bbfe;
            border-right: 0;
        }
    }
}

.article-names {
    font-size: 1.75vw;
    font-weight: 400;
    color: black;
    opacity: 0.8;
    margin: 0;
    padding: 1.25vw;
    border-bottom: 0.069vw solid #e0e0e0;
}

.detailed-article-content {
    width: 75%;
    padding-left: 1.597vw;
    overflow-y: auto;
    max-height: calc(calc(100vh - 4.51vh - 11.81vh));
    overflow-x: hidden;

    h1 {
        margin-top: 1vw;

        a {
            text-decoration: none;
            font-size: 2vw;
            color: #c97c00;
        }

        a:hover {
            text-decoration: underline;
        }

    }

    h3 {
        font-size: 1.375vw;
        margin: 1vw 0 0.5vw 0;
    }

    div {
        p {
            font-size: 1.125vw;
            margin: 0.5vw 0 1.5vw 0;
        }

        b {
            font-size: 1.125vw;
            color: #000000;
            margin-top: 0.972vw;
        }

        a {
            color: #3498db;
            font-size: 1.111vw;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            margin: 0;

            li {
                margin: 0.6vw 0;
                font-size: 1.111vw;
            }
        }

        img {
            width: fit-content;
            pointer-events: none;
        }
    }


    #brandAndModelTableStyle {
        border: none;
        border-collapse: collapse;
        width: 100%;
        margin: 1.25vw 0;

        tbody {
            tr {
                td {
                    border: none;
                    padding: 0.5vw;
                    font-size: 1.111vw;
                }
            }
        }
    }

    #brandAndModelTableStyle~p {
        justify-content: space-between;
        display: flex;
        background: -webkit-gradient(linear, left bottom, left top, from(#C3E0FC), to(#FFF));
        padding: 0.7vw;

        a {
            font-weight: 600;
            font-size: 1.5vw;
        }
    }

    #brandAndModelTableStyle~table {
        border-collapse: collapse;
        width: 100%;
        margin: 1.25vw 0;

        tbody {
            tr {
                td {
                    border: none;
                    padding: 0.5vw;
                    font-size: 1.111vw;
                }
            }
        }
    }
}

@media screen and (max-width: 430px) {
    .detailed-article {
        flex-direction: column-reverse;

        .detailed-article-content {
            width: 97%;
            max-height: none;
            overflow-y: scroll;

            h1 {
                margin: 1vw 0 1vw 0;

                a {
                    font-size: 5.1vw;
                }
            }

            hr {
                margin: 0;
            }

            h3 {
                font-size: 4.5vw;
                margin: 1vw 0 0.5vw 0;
            }

            div {
                p {
                    font-size: 3.5vw;
                    margin: 0.5vw 0 1.5vw 0;

                    span {
                        strong {
                            font-size: 3.5vw;
                        }
                    }
                }

                b {
                    font-size: 3.5vw;
                    margin-top: 0.972vw;
                }

                a {
                    font-size: 3.5vw;
                }

                ul {
                    margin: 0;
                    padding-left: 5.25vw;

                    li {
                        margin: 0.6vw 0;
                        font-size: 3.5vw;

                        span {
                            img {
                                width: 15%;
                            }
                        }
                    }
                }

                img {
                    width: 95%;
                }
            }
        }

        .more-article {
            width: 100%;
            max-height: none;
            overflow-y: scroll;

            h5 {
                font-size: 5.5vw;
                margin: 0;
                padding: 3.25vw 0 3.25vw 3vw;
                border-bottom: 0.069vw solid #e0e0e0;
                border-top: 0.069vw solid #e0e0e0;
            }

            p {
                font-size: 4.5vw;
                padding: 3vw;
            }
        }
    }

    .detailed-article-content {
        width: 97%;
        max-height: none;
        overflow-y: scroll;

        h1 {
            margin: 1vw 0 1vw 0;

            a {
                font-size: 5.1vw;
            }
        }

        hr {
            margin: 0;
        }

        h3 {
            font-size: 4.5vw;
            margin: 1vw 0 0.5vw 0;
        }

        div {
            p {
                font-size: 3.5vw;
                margin: 0.5vw 0 1.5vw 0;

                span {
                    strong {
                        font-size: 3.5vw;
                    }
                }
            }

            b {
                font-size: 3.5vw;
                margin-top: 0.972vw;
            }

            a {
                font-size: 3.5vw;
            }

            ul {
                margin: 0;
                padding-left: 5.25vw;

                li {
                    margin: 0.6vw 0;
                    font-size: 3.5vw;

                    span {
                        img {
                            width: 15%;
                        }
                    }
                }
            }

            img {
                width: 95%;
            }
        }
    }
}