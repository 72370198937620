.download-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.download-header {
    width: 100%;
    background-color: #DDEAFF;
    border-bottom: 0.069vw solid #91bbfe;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1.114vw 0;
}

.download-title {
    font-size: 2.25vw;
    margin: 0;
}

.download-description {
    font-size: 1.181vw;
    line-height: 2.014vw;

    p{
        font-size: 1.25vw;
    }
}

.download-content {
    display: flex;
    gap: 1.25vw;
    flex-direction: column;
    margin: 3.125vw;
    max-width: 111.111vw;
    padding: 0 1.25vw;
}

.download-type {
    border-radius: 0.625vw;
    border: 0.069vw solid #91bbfe;
    position: relative;
}

.type-name {
    font-size: 1.5625vw;
    padding: 0.972vw 1.25vw;
    margin: 0;
    background-color: #DDEAFF;
    border-radius: 0.625vw 0.625vw 0 0;
    color: #0d5fc2;
}

.download-product {
    width: 100%;
}

.download-type>table,
td {
    border-top: 0.069vw solid #b6b6b6;
    border-collapse: collapse;
}

.download-product-heading {
    margin: 0;
    background-color: #f3f8ff;
    border-radius: 0.625vw 0 0 0;
}

.download-product-heading>tr>th {
    font-size: 1.125vw;
    font-weight: 400;
    color: rgb(85, 85, 85);
    padding: 0.417vw 0;
    text-align: center;
}

.download-product-list>tr>td>a {
    text-decoration: none;
    color: black;
}

.download-product-list>tr>td:nth-child(1) {
    width: 22%;
    font-size: 1.375vw;
    padding-left: 0.972vw;
}

.download-product-list>tr>td:nth-child(2) {
    width: 38%;
    padding-left: 5.972vw;
    font-size: 1vw;
}

.download-product-list>tr>td:nth-child(3) {
    width: 10%;
    text-align: center;
    font-size: 1vw;
}

.download-product-list>tr>td:nth-child(4) {
    padding-left: 3vw;
    width: 16%;
    font-size: 1.111vw;
    font-size: 1vw;
}

.download-product-list>tr>td:nth-child(5) {
    width: 19%;
    padding-right: 0.625vw;
    text-align: -webkit-center;
}

.download-product-list>tr>td {
    padding: 0.972vw 0.139vw;
}

.download-product-name {
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.625vw;
    margin: 0;
}

.download-version {
    display: flex;
    flex-direction: column;
}

.downloadpage-icon {
    font-size: 1.25vw;
}

.product-download-image {
    width: 10%;
}

@media screen and (max-width:430px) {
    .download-page {
        padding: 0;
    }

    .download-header {
        padding: 1.5rem 0;
    }

    .download-title {
        font-size: 1.5rem;
    }

    .download-description {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .download-content {
        margin: 1.5rem 0;
        gap: 5.972vw;
    }

    .download-type {
        border-radius: 1.5rem;
    }

    .type-name {
        font-size: 1.25rem;
        padding: 1rem 1.25rem;
        text-align: center;
        border-radius: 5.625vw 5.625vw 0 0;
    }

    .download-product-heading>tr>th {
        font-size: 0.8rem;
        padding: 0.5rem 0;
        font-weight: 700;
    }

    .download-product-heading>tr>th:nth-child(5) {
        display: none;
    }

    .download-product-list>tr>td>a {
        font-size: 2.986vw;
    }

    .download-product-list>tr>td:nth-child(1) {
        width: 20%;
        font-size: 2.986vw;
    }

    .download-product-list>tr>td:nth-child(2) {
        width: 40%;
        padding-left: 0.972vw;
    }

    .download-product-list>tr>td:nth-child(3) {
        width: 10%;
        text-align: center;
    }

    .download-product-list>tr>td:nth-child(4) {
        width: 16%;
    }

    .download-product-list>tr>td:nth-child(5) {
        display: none;
    }

    .download-product-list>tr>td {
        padding: 0.9375rem 0.125rem;
    }

    .product-name>img {
        display: none;
        pointer-events: none;
    }

    .download-version {
        display: flex;
        flex-direction: column;
    }

    .downloadpage-icon {
        font-size: 1.3rem;
    }

    .product-download-image {
        width: 10%;
    }

    .product-name>span {
        font-size: 4.028vw;
    }
}