.video-container {
    margin-right: 2.1875vw;
}

.video-container>h2 {
    color: var(--text-color);
    font-weight: 600;
    margin: 0;
    width: -webkit-fill-available;
    font-size: 1.375vw;
}

.video {
    width: 25.6vw;
    display: block;
    cursor: pointer;
}

.screenshots {
    font-size: 0.875vw;
    border: 0.0625vw solid var(--text-color);
    margin-left: 0.0625vw;

}

.screenshot {
    background-color: var(--background-color);
    font-size: 0.875vw;
    border-radius: 0.3125vw;
    padding: 0.625vw;
}

.VideoPopupDiv {
    display: none;
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.8);
}

.VideoPopupDiv>div {
    margin: auto;
    max-width: 55vw;
    border-radius: 50%;
    padding: -0.4375vw 1.25vw;
}

.PlayIcon {
    position: absolute;
    margin: auto;
    top: 41%;
    font-size: 4.375vw;
    border-radius: 50%;
    display: flex;
    left: 0;
    right: 0;
    width: fit-content;
}

.InnerVideoDiv {
    position: absolute;
    top: 0;
    bottom: 0;
    height: fit-content;
    left: 0;
    right: 0;

    div {
        .react-slideshow-container {
            .react-slideshow-wrapper {
                border: none;
                border-radius: unset;
            }
        }
    }
}

.CloseVideoPopupButton,
.CloseImagePopupButton {
    position: absolute;
    top: -1.25vw;
    right: -1.25vw;
    border-radius: 50%;
    color: black;
    background-color: var(--background-color);
    font-weight: bold;
    border: none;
    font-size: 2.1875vw;
    padding: 0vw 0vw;
    cursor: pointer;
    display: flex;
}

.image-button {
    border: none;
    background-color: transparent;
    padding: 0;
    width: 100%;

    img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        pointer-events: none;
    }
}

.slider-image-button {
    border: none;
    background-color: transparent;
    padding: 0;
    width: 100%;
}

.slider-image-button>img {
    object-fit: contain;
    width: 100%;
    pointer-events: none;
}

.react-slideshow-container .default-nav:last-of-type,
.default-nav:first-of-type {
    background-color: #cfe6f1;
}

.react-slideshow-container button:disabled {
    display: none !important;
}

.imageText {
    font-weight: bold;
    margin-top: 0vw;
    color: var(--text-color);
}

.product-images {
    .react-slideshow-wrapper {
        border: none;
        border-radius: unset;
    }
}


/* mobile view */

@media only screen and (max-width: 430px) {
    .video-container {
        margin-right: 0;

        h2 {
            font-size: 5vw;
            margin-bottom: 1.4375vw;
        }
    }

    .video-line {
        margin: 2vw 0;
    }

    .video {
        width: 94.6vw;
    }

    .imp-feature {
        font-size: 3.5vw;
    }

    .screenshots {
        font-size: 3.5vw;
    }

    .screenshot {
        font-size: 3.5vw;
        padding: 1;
    }

    .imageText {
        font-size: 3.5vw;
    }

    .image-button {
        padding: 0;
    }

    .react-slideshow-container .default-nav:last-of-type,
    .default-nav:first-of-type {
        background-color: #cfe6f1;
    }

    .react-slideshow-container .default-nav:last-of-type {
        right: 0;
    }

    .react-slideshow-container .default-nav:first-of-type {
        left: 0;
    }

    .VideoPopupDiv>div {
        max-width: 95%;
    }

    .CloseVideoPopupButton,
    .CloseImagePopupButton {
        top: -3.25vw;
        right: -2.25vw;
        font-size: 5vw;
    }

}

/* small screen laptop */
@media screen and (max-width: 1200px) and (min-width: 900px) {
    .VideoPopupDiv>div {
        max-width: 80vw;

        div {
            .react-slideshow-wrapper {
                border: none;
                border-radius: unset;
            }
        }
    }
}