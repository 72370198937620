.key-header {
    padding: 2.9vw 24.2vw;
    background-color: #DDEAFF;
    border-bottom: 0.069vw solid #91bbfe;
}

.key-header>h1 {
    text-align: start;
    font-size: 1.625rem;
    margin-top: 0;
    margin-bottom: 0.747vw;
    color: #01183d;
}

.key-header>p {
    margin-top: 0;
    font-size: 1.125rem;
    font-weight: 400;
    margin-bottom: 0.5vw;

    a {
        color: #00418f;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.lost-license-form {
    width: 53.6vw;
    margin: 0 auto;
    position: relative;
}

.key-form {
    margin: 1rem auto 6rem auto;
    border: 0.069vw solid gray;
    border-radius: 0.625vw;
}

.key-signin {
    padding: 0.889vw 0.889vw 1.789vw 0.889vw;
}

.key-signin>p:nth-child(1) {
    font-size: 1.5rem;
    color: #00418f;
    margin: 0;
    font-weight: 500;
}

.key-signin>p {
    font-size: 1.125rem;
    margin: 0.731vw 0 0.131vw 0;
}

.key-signin>p:nth-child(3) {
    font-weight: 500;
}

.lost-license-form>button {
    text-decoration: none;
    font-size: 1.125rem;
    background-color: unset;
    color: #00418f;
    font-weight: bold;
    padding: 0.486vw 1.528vw;
    border: 0.139vw solid #00418f;
    border-radius: 1.597vw;
    position: absolute;
    right: 0;
    bottom: -14%;

    &:hover {
        background-color: #00418f;
        color: white;
    }

    &:focus {
        outline: none;
    }
}

.key-account-form {
    width: 90%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto auto auto auto;
    gap: 0.625vw;
    column-gap: 2.597vw;
}

.key-account-form>div {
    position: relative;
}

.key-account-form>label {
    font-size: 1.125rem;
    font-weight: 500;
    color: black;
    margin-top: 1vw;
}

.key-account-form>div>input {
    padding: 0.486vw 0.764vw;
    border: 0.069vw solid #8a7575;
    border-radius: 0.347vw;
    font-size: 1rem;
    outline: none;
    width: 21.2vw;
}

.key-account-form .react-tel-input .form-control {
    padding-left: 2.925vw;
    border: 0.069vw solid #8a7575;
    border-radius: 0.347vw;
    font-size: 1rem;
    margin-left: 0.347vw;
    width: 22.5vw;
    height: 2.231vw;
    outline: none;
}

#date:invalid {
    color: #8a7575 !important;
}

.react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container {

        width: 100%;

        input {
            width: 91%;
            color: #8a7575;
            font-size: 1rem;
            padding: 0.486vw 0.764vw;
            border-radius: 0.347vw;
            border: 0.069vw solid #8a7575;
            outline: none;
        }

        svg {
            right: 1vw;
        }
    }
}

#date:valid {
    color: black;
}

.key-account-form>div>select {
    color: #8a7575;
    padding: 0.486vw 0.764vw;
    border: 0.069vw solid #8a7575;
    border-radius: 0.347vw;
    font-size: 1rem;
    overflow: auto;
    outline: none;
    width: 23vw;
}

.key-account-form>div>select>option {
    color: black;
}

.key-account-form>div>select>option:checked {
    font-weight: bold;
}

.key-account-form>div>.error-message {
    position: absolute;
    color: red;
    font-size: 0.833vw;
    margin: 0;
    top: 2.1vw;
}

input::placeholder {
    color: #b8b8b8;
}

textarea::placeholder {
    color: #b8b8b8;
}

input[type="date"]::placeholder {
    color: #b8b8b8;
}

@media screen and (max-width: 1440px) {
    .key-account-form>div>input {
        width: 21.5vw;
    }

    .key-account-form>div>select {
        width: 23.5vw;
    }

    .key-account-form .react-tel-input .form-control {
        width: 22.8vw;
        height: 2.531vw;
    }

    .key-account-form>div>.error-message {
        top: 2.6vw;
    }
}

@media screen and (max-width: 430px) {
    .key-header {
        padding: 10vw 10vw 5vw 10vw;

        h1 {
            font-size: 7.4vw;
        }

        p {
            font-size: 5.1vw;
        }
    }

    .lost-license-form {
        .key-form {
            border-radius: 4vw;

            .key-signin {
                padding: 4vw 4vw 5vw 4vw;

                p:nth-child(1) {
                    font-size: 6.6vw;
                }

                p:nth-child(2) {
                    font-size: 4.6vw;
                    padding-bottom: 5vw;
                    margin-bottom: 0;
                }

                a {
                    font-size: 4.6vw;
                    padding: 2vw 6vw;
                    border-radius: 8vw;
                }

                form {

                    .key-account-form label[for="phone"],
                    .key-account-form div:nth-child(8) {
                        order: 1;
                        margin-top: 1vw;
                        width: 101%;
                    }

                    .key-account-form label[for="application"],
                    .key-account-form div:nth-child(11) {
                        order: 2;
                    }

                    .key-account-form label[for="date"],
                    .key-account-form div:nth-child(12) {
                        order: 3;
                        margin-top: 1vw;
                    }

                    .key-account-form {
                        gap: 0;
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin-bottom: 10vw;

                        label {
                            font-size: 4.6vw;
                            margin-top: 3vw;
                        }

                        div {
                            input {
                                font-size: 4.6vw;
                                margin: 1vw 0 4vw 0;
                                width: 95%;
                                border-radius: 1vw;
                                padding: 1.625vw;
                            }

                            .error-message {
                                font-size: 3.5vw;
                                top: 10vw;
                                padding-top: 0.3vw;
                            }

                            select {
                                font-size: 4.6vw;
                                margin: 1vw 0 4vw 0;
                                border-radius: 1vw;
                                padding: 1.625vw;
                            }
                        }

                        .react-tel-input {
                            margin-top: 1vw;

                            .form-control {
                                padding-left: 10.5vw;
                                font-size: 4.5vw;
                                height: 10vw;
                                width: 98.6%;
                            }

                            .flag-dropdown {
                                .selected-flag {
                                    padding: 0 0 0 1.55vw;
                                    width: 10vw;

                                    .flag {
                                        margin-top: -1.3125vw;
                                    }
                                }
                            }
                        }
                    }

                    button {
                        font-size: 4.6vw;
                        padding: 2vw 6vw;
                        border-radius: 8vw;
                    }
                }
            }
        }
    }
}