.priority {
    max-width: 75.5vw;
    margin: 0 auto;
    padding: 0 1.25vw;
}

.priority-title {
    font-weight: bold;
    color: #c97c00;
    border-bottom: 0.069vw solid #0000006e;
    padding-bottom: 0.347vw;
    font-size: 1.597vw;
}

.support {
    color: black;
}

.upgrade {
    margin: 2.153vw 0;
    background-color: #e2efff;
    padding: 2.014vw;
    display: flex;
    align-items: flex-start;
    font-size: 1.25vw;
}

.upgrade>img {
    width: 13vw;
    margin-right: 1vw;
}

.upgrade-content {
    padding-right: 10.278vw;
}

.upgrade-content>h2 {
    margin-top: 0;
    font-size: 2vw;
}

.upgrade-content>p {
    margin-top: 0;
    line-height: 2.014vw;
}

.upgrade-price {
    display: flex;
    align-items: center;
    gap: 1.25vw;
}

.upgrade-price>button {
    display: flex;
    align-items: center;
    padding: 0.764vw 1.528vw;
    background-color: #1474e4;
    color: white;
    width: fit-content;
    border-radius: 0.347vw;
    border: none;
    height: 2.986vw;
    font-weight: bold;
    cursor: pointer;
}

.upgrade-price>button>span {
    font-size: 1.25vw;
}

.price>span:nth-child(2) {
    color: rgb(0, 128, 0);
    font-weight: 600;
}

.priority-content {
    font-size: 1.111vw;
}

@media screen and (max-width:430px) {
    .priority {
        max-width: unset;
        padding: 0 2.25vw;

        .priority-title {
            font-size: 7.389vw;
            padding-bottom: 2.014vw;
        }

        .upgrade {
            padding: 4.028vw 2.014vw;
            flex-direction: column;
            align-items: center;

            img {
                width: 40vw;
                margin-right: 0;
            }

            .upgrade-content {
                padding-right: 0;

                h2 {
                    text-align: center;
                    font-size: 8.75vw;

                    br {
                        display: none;
                    }
                }

                p {
                    line-height: 7.25vw;
                    font-size: 6.028vw;
                }

                .upgrade-price {
                    gap: 2.014vw;
                    margin-top: 2.014vw;
                    flex-direction: column;

                    .price {
                        font-size: 6.028vw;
                    }

                    button {
                        justify-content: center;
                        padding: 2.014vw 4.028vw;
                        height: 9.028vw;
                        width: 100%;
                        border-radius: 2.014vw;
                        height: 12vw;

                        svg{
                            width: 10.028vw;
                        }

                        span{
                            font-size: 6.028vw;
                        }
                    }
                }
            }
        }

        .priority-content {
            h3{
                font-size: 6.028vw;
                margin: 3vw 0 1vw 0;
            }

            p{
                font-size: 5.028vw;
                margin: 1vw 0 2vw 0;
            }
        }
    }
}