.updates {
    padding: 2.5vw;
    width: 100%;
}

.update-title {
    font-size: 1.667vw;
    padding-bottom: 0.486vw;
    margin: 0;
    text-align: center;
}

.product-updates {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

}

.updates-list {
    width: 70%;
    margin: 0 auto;
    border-collapse: collapse;
}

.updated-product-name,
.product-version,
.update-date {
    font-size: 1.111vw;
}


.updates-list>tbody>tr>td {
    border-top: none;
    padding: 0.764vw 0;
    font-size: 1.25vw;
}


.updates-list>tbody>tr {
    border-bottom: 0.069vw solid rgb(129, 123, 123, 0.5);
    border-top: none;
    text-align: left;
}

.updates-list>tbody>tr>td:nth-child(1) {
    width: 3%;
}

.updates-list>tbody>tr>td:nth-child(2) {
    width: 67%;
    font-weight: 400;
}

.updates-list>tbody>tr>td:nth-child(3) {
    width: 10%;
}

.updates-list>tbody>tr>td:nth-child(4) {
    width: 20%;
    text-align: end;
}

.updates-list>tbody>tr>td>a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

@media screen and (max-width:430px) {
    .updates {
        padding: 0 5vw 5vw 5vw;
        width: unset;
    }

    .update-title {
        font-size: 5vw;
        padding-bottom: 5vw;
    }

    .updates-list {
        width: 100%;
    }

    .updates-list>tbody>tr>td{
        padding: 2vw 0;
        font-size:3.8vw;
    }

    .updates-list>tbody>tr>td:nth-child(1) {
        width: 5%;
    }

    .updates-list>tbody>tr>td:nth-child(2) {
        width: 85%;
    }

    .updates-list>tbody>tr>td:nth-child(3) {
        width: 10%;
    }

    .updates-list>tbody>tr>td:nth-child(4) {
        display: none;
    }

}