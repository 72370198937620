.comparison {
    border: 0.069vw #aba9a9bf solid;
    border-radius: 1.25vw;
}

.comparison-title {
    font-size: 1.389vw;
    font-weight: 600;
    background-color: #d5eafd;
    color: black;
    padding: 1.25vw;
    border-top-right-radius: 1.25vw;
    border-top-left-radius: 1.25vw;
    border-bottom: 0.069vw #aba9a9bf solid;
    margin: 0vw;
}

.comparison-sub-title {
    display: flex;
    justify-content: center;
    font-weight: 600;
    width: -webkit-fill-available;
    font-size: 1.25vw;
    color: var(--text-color);
}

.comparison-table {
    width: 100%;
    margin: 0;
    display: inline-table;
    table-layout: fixed;
    border-collapse: collapse;
}

.comparison-table-body {
    display: table-row-group;
    vertical-align: middle;
}

.comparison-table-heading {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}


.heading-feature {
    font-size: 1.111vw;
    font-weight: 600;
    text-align: center;
    padding: 0.625vw;
    width: 40%;
    border-top: 0.069vw solid var(--text-color);
    border-bottom: 0.069vw solid var(--text-color);
    background-color: #c8e3ff6b;
    color: var(--text-color);
}

.heading-data {
    font-size: 1.042vw;
    font-weight: 600;
    width: 20%;
    border-top: 0.069vw solid var(--text-color);
    border-bottom: 0.069vw solid var(--text-color);
    background-color: #c8e3ff6b;
    padding: 0.972vw;
    text-align: center;
    color: var(--text-color);
}

.heading-subfeature>td {
    border: none;
}

.feature-unused>td {
    border: none;
}


.heading-data-current-product-text {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.111vw;
    font-weight: 600;
}

.heading-data-other-product-text,
.learn-more>a {
    font-size: 1.111vw;
    font-weight: 600;
    text-decoration: none;
    color: var(--link-text);
}

.learn-more-bottom>a {
    font-size: 1.042vw;
    font-weight: 600;
    text-decoration: none;
    color: var(--link-text);
}

.buy-download>a {
    font-size: 1.042vw;
    font-weight: 600;
    text-decoration: none;
    color: var(--link-text);
}

.heading-data-current-product-text-bottom {
    color: var(--link-text);
    text-decoration: none;
    font-weight: 400;
    font-size: 1.042vw;
}

.heading-data-other-product-text:hover,
.heading-data-current-product-text-bottom:hover,
.heading-data-other-product-text-bottom:hover,
.learn-more>a:hover,
.learn-more-bottom>a:hover,
.buy-download>a:hover {
    text-decoration: underline;
}

.heading-data-other-product-text-bottom {
    font-size: 1.042vw;
    font-weight: 400;
    text-decoration: none;
    color: #2980b9;
}

.sub-heading-data {
    background-color: #badbfbbd;
    padding: 0.625vw;
    text-align: left;
    font-size: 1.042vw;
}

.sub-heading-data>b {
    color: var(--text-color);
}

.featurename {
    padding: 0.625vw 0.625vw 0.625vw 1.111vw;
    text-align: center;
    font-size: 1.042vw;

    svg {
        height: 1.25vw;
    }
}

.featurename-left {
    padding: 0.625vw 0.625vw 0.625vw 1.111vw;
    text-align: left;
    font-size: 1.042vw;
}

.featurename-design {
    background-color: rgb(242 242 242 / 57%);
}

/* Mobile view */

@media only screen and (max-width:430px) {

    .comparison {
        border-radius: 5vw;
        width: 98%;
    }

    .comparison-title {
        font-size: 5.6vw;
        border-top-right-radius: 5vw;
        border-top-left-radius: 5vw;
        padding: 2vw 3vw;
    }

    .comparison-table-headings {
        border-bottom: 5vw;
    }

    .heading-feature {
        font-size: 5.1vw;
    }

    .sub-heading-data {
        font-size: 5.1vw;
    }

    .heading-data {
        line-height: 4.028vw;
        padding: 2.172vw;
    }

    .heading-data>a {
        font-size: 5.1vw;
    }

    .learn-more>a {
        font-size: 4.8vw;
    }

    .featurename {
        font-size: 4.6vw;
        line-height: 7vw;
        padding: 0.625vw 0.625vw 1.625vw 1.111vw;
    }

    .featurename>svg {
        width: 4.6vw;
        height: 4.6vw;
    }

    .featurename-left {
        font-size: 4.6vw;
        line-height: 7vw;
        padding: 0.625vw 0.625vw 1.625vw 1.111vw;
    }

    .buy-download>a {
        font-size: 4.6vw;
    }

    .learn-more-bottom>a {
        font-size: 4.6vw;
    }
}