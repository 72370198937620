.quicktips {
    border-radius: 1.389vw;
    margin-bottom: 1.25vw;
    border: 0.139vw #aba9a9bf solid;
}

.sidecardheader {
    font-weight: 600;
    font-size: 1.389vw;
    background-color: #d5eafd;
    color: black;
    padding: 1.181vw;
    border-top-right-radius: 1.25vw;
    border-bottom: 0.069vw #aba9a9bf solid;
    border-top-left-radius: 1.25vw;
}

.sidecardsection {
    padding: 0.347vw 0.347vw;
}

.sidecardsection>ul {
    line-height: 1.5625vw;

    li>a {
        color: var(--link-text);
        text-decoration: none;
        font-size: 1.042vw;
        outline: 0;
    }
}

.sidecardsection>ul>li>a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 430px) {
    .quicktips {
        margin-bottom: 7.986vw;
        border-radius: 5vw;
    }

    .sidecardheader {
        font-size: 4.375vw;
        padding: 4.375vw;
    }

    .sidecardsection>ul>li>a {
        font-size: 4.575vw;
        margin: 2.014vw 0;
    }

    .sidecardsection>ul>li {
        margin: 2.014vw 0;
        line-height: 5.5vw;
    }
}