.contactus-success {
    height: 81%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 4.5vw;
    z-index: 100;
    left: 0%;
    bottom: 0;
    background-color: white;
    /* backdrop-filter: blur(10px); */

    .success-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        svg {
            width: 25%;
        }

        .success-message {
            margin-top: 2vw;
            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
                font-size: 3.25vw;
                margin: 0;
            }

            p {
                font-size: 1.25vw;
                margin: 1vw 33.25vw;
                text-align: center;
            }
        }

        button {
            font-size: 1.25vw;
            padding: 0.486vw 2.528vw;
            background-color: #00418f;
            border: unset;
            border-radius: 1.597vw;
            cursor: pointer;
            margin-top: 3vw;

            a {
                text-decoration: none;
                color: white;
            }
        }
    }
}

@media screen and (max-width:430px) {
    .contactus-success {
        top: 11.5vw;
        height: 78.2%;

        .success-page {
            position: absolute;
            top: 5vw;

            svg {
                width: 50%;
            }

            .success-message {
                margin: 1vw 0;

                h3 {
                    font-size: 6.7vw;
                }

                p {
                    font-size: 5.5vw;
                    margin: 3vw 0;
                }
            }

            button {
                font-size: 5.5vw;
                padding: 2.5vw 13vw;
                border-radius: 6vw;
            }
        }
    }
}