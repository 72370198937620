.key-information {
    display: grid;
    gap: 0.972vw;
    grid-template-columns: 3.1fr 0.9fr;
    grid-template-areas:
        "content sidebar"
        "header header"
        "footer footer";
    padding: 0.764vw;
}

@media screen and (max-width: 768px) {
    .key-information {
        padding-top: 5vw;
        grid-template-columns: 1fr;
        width: 100%;
        grid-template-areas:
            "content"
            "header"
            "footer"
            "sidebar";
    }
}

@media screen and (max-width:1140px) and (min-width: 430px) {
    .key-information {
        width: 99%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            "characteristics characteristics"
            "comparison comparison"
            "quick-letter quick-letter";
    }

    .characteristics {
        grid-area: characteristics;
    }

    .comparison {
        grid-area: comparison;
    }

    .quick-letter {
        grid-area: quick-letter;
    }
}