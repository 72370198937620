.review-page {
    display: flex;
    flex-direction: column;
}

.reviews-header {
    color: #000;
    background-color: #DDEAFF;
    border-bottom: 0.069vw solid #91bbfe;
    display: flex;
    gap: 5vw;
    align-items: center;
    justify-content: space-between;
    padding: 2.014vw 6vw 2.014vw 6vw;

    a {
        text-decoration: none;
        color: #002b79;

        &:hover {
            text-decoration: underline;
        }
    }
}

.review-header-text>h1 {
    margin: 0 0 0.486vw 0;
    font-size: 1.875vw;
    color: #01183d;
}

.review-header-text>p {
    margin-top: 0;
    font-size: 1.111vw;
    font-weight: 400;
    color: #000000;
    opacity: 0.8;
}

.buy-trial {
    display: flex;
    gap: 0.972vw;

    div>a>button {
        width: 15vw;
        height: 3.125vw;
        color: #fff;
        border: none;
        padding: 0.486vw 1.528vw;
        border-radius: 0.486vw;
        font-size: 1.25vw;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
}

.free>a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        background-color: #095bbf;
    }

    p {
        display: flex;
        align-items: center;
        gap: 0.486vw;
        margin: 0.972vw 0;
        font-size: 1.25vw;

        svg {
            width: 1.25vw;
            height: 1.25vw;
        }

        b {
            text-decoration: underline;
            color: #000000a8;
        }
    }
}

.buy>a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;

    button {
        background-color: #28a745;

        &:hover {
            opacity: 0.8;
        }
    }

    p {
        color: #28a745;
        margin: 0.972vw 0;
        font-size: 1.25vw;
    }
}

.button-text {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.972vw;

    svg {
        width: 1.25vw;
        height: 1.25vw;
    }
}

.review-container {
    max-width: 87.5vw;
    margin: 1vw 0 auto 5vw;
    padding: 0 1.25vw;

    a {
        text-decoration: none;
    }

}

.review-heading {
    font-size: 1.25vw;
}

.review-description {
    font-size: 1.25vw;
}

.review-block {
    border: 0.069vw solid #91bbfe;
    border-radius: 0.486vw;
    box-shadow: 0 0.139vw 0.278vw rgba(0, 0, 0, 0.1);
    margin: 1.25vw 0;
    padding: 0 1.25vw;
    width: 80%;
}

.review-title {
    color: #004599;
    margin: 1.25vw 0 0.278vw 0;
    font-size: 1.111vw;
}

.review {
    color: #000;
    font-size: 0.972vw;
    margin: 0;
    padding: 0;
}

.reviewer {
    color: #000;
    font-size: 0.972vw;
    padding: 0;
    margin: 0.278vw 0 1.25vw 0;
}

.next-page {
    border-radius: 0.486vw;
    box-shadow: 0 0.139vw 0.278vw rgba(0, 0, 0, 0.1);
    margin: 1.25vw 0;
    padding: 0 1.25vw;
    width: 80%;
    background-color: #095bbf;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        box-shadow: 0 0.278vw 0.486vw rgba(0, 0, 0, 0.2);
    }
}

.next-title {
    color: #fff;
    font-size: 1.528vw;
    margin: 1.25vw 0 0.278vw 0;
}

.read-more {
    font-size: 1.111vw;
    color: #fff;
    margin: 0.278vw 0 1.25vw 0;
}

.next-page-icon {
    width: 2.222vw;
    height: 2.222vw;
    fill: #fff;
}

@media screen and (max-width: 430px) {
    .review-page {
        .reviews-header {
            padding: 2vw 4vw 2vw 4vw;
            flex-direction: column;
            gap: 1.25vw;

            .review-header-text {
                h1 {
                    font-size: 5.125vw;
                    text-align: center;
                }

                p {
                    font-size: 4.522vw;
                    text-align: center;
                }
            }

            .buy-trial {
                gap: 5.972vw;

                .free {
                    display: none;

                    a {
                        button {
                            width: 40vw;
                            height: 10vw;
                            font-size: 5vw;
                            padding: 2vw 4vw;
                            border-radius: 2vw;

                            .button-text {
                                gap: 2vw;

                                svg {
                                    width: 4vw;
                                    height: 4vw;
                                }

                                b {
                                    font-size: 4vw;
                                }
                            }
                        }

                        p {
                            gap: 2vw;

                            svg {
                                width: 5vw;
                                height: 5vw;
                            }

                            b {
                                font-size: 4vw;
                            }
                        }
                    }
                }
            }

            .buy {
                a {
                    button {
                        width: 40vw;
                        height: 10vw;
                        font-size: 5vw;
                        padding: 2vw 6vw;
                        border-radius: 2vw
                    }

                    p {
                        b {
                            font-size: 4vw;
                        }
                    }
                }
            }
        }

        .review-container {
            max-width: unset;
            margin: 0;
            padding: 0 2vw;
            width: 92%;

            .review-heading {
                font-size: 5.125vw;
            }

            .review-block {
                width: 95%;
                margin: 4.25vw 0;
                padding: 3vw;

                .review-title {
                    font-size: 4.522vw;
                }

                .review {
                    font-size: 4.017vw;
                }

                .reviewer {
                    font-size: 4.017vw;
                }
            }

            a {
                .next-page {
                    width: 93%;
                    margin: 4.25vw 0;
                    padding: 0 4vw;
                    border-radius: 2vw;

                    div {
                        .next-title {
                            font-size: 6.25vw;
                            margin: 1.25vw 0 1.25vw 0;
                        }

                        .read-more {
                            font-size: 4.522vw;
                            margin: 1.25vw 0;
                        }
                    }

                    svg {
                        width: 6.25vw;
                        height: 6.25vw;
                    }
                }
            }
        }
    }
}