.request-camera-page {
    max-width: 90vw;
    margin: 0 auto;
    padding: 0 1.25vw;

    h1 {
        color: #c97c00;
        font-size: 1.667vw;
    }
}

.forms>form {
    position: relative;
}

.form-container {
    margin-bottom: 5vw;
    display: flex;
    gap: 1.25vw;
}

.request-form-block {
    background-color: #E2F0FF;
    border-radius: 0.625vw;
    display: flex;
    flex-direction: column;
    padding: 2.5vw;
    align-items: center;

    p {
        margin: 0;

        b {
            font-size: 1.25vw;
            font-weight: 600;
        }
    }

    hr {
        width: 100%;
    }
}

.camera-request-form1 {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto auto auto;
    gap: 0.625vw;
    column-gap: 5.972vw;

    textarea {
        font-family: 'Inter', sans-serif;
        border: 0.069vw solid #8a7575;
        border-radius: 0.625vw;
        padding-left: 0.625vw;
        font-size: 1.25vw;
        font-weight: 400;
        outline: none;
        resize: none;
        width: 100%;
        height: 8.611vw;
        margin-top: 0.347vw
    }
}

.camera-request-form2 {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto auto;
    gap: 0.625vw;
    column-gap: 9.028vw;

    textarea {
        font-family: 'Inter', sans-serif;
        border: 0.069vw solid #8a7575;
        border-radius: 0.625vw;
        padding-left: 0.625vw;
        font-size: 1.25vw;
        font-weight: 400;
        outline: none;
        resize: none;
        width: 100%;
        height: 8.611vw;
        margin-top: 0.347vw
    }
}

.camera-request-form1>div,
.camera-request-form2>div {
    position: relative;
}

.camera-request-form1>label,
.camera-request-form2>label {
    font-size: 1.111vw;
    font-weight: 600;
    color: black;
    margin-top: 1.875vw;
}

.camera-request-form1>div>input,
.camera-request-form2>div>input,
.camera-request-form2>input {
    padding: 0.486vw 0.764vw;
    border: 0.069vw solid #8a7575;
    border-radius: 0.347vw;
    font-size: 1.111vw;
    outline: none;
    width: 22.986vw;
}

input::placeholder {
    color: #b8b8b8;
}

textarea::placeholder {
    color: #b8b8b8;
}

.error-message {
    position: absolute;
    color: red;
    font-size: 0.833vw;
    margin: 0;
    top: 2.5vw;
}

.react-tel-input .form-control {
    padding-left: 2.325rem;
    border: 0.069vw solid #8a7575;
    border-radius: 0.347vw;
    font-size: 1.111vw;
    margin-left: 0.347vw;
    width: 100%;
    height: 2.431vw;
    outline: none;
}

.form-submit {
    position: absolute;
    right: 0;
    bottom: -4.028vw;

    padding: 0.625vw 1.25vw;
    font-size: 1.111vw;
    font-weight: bold;
    color: rgb(0, 137, 191);
    background-color: white;
    cursor: pointer;
    outline: none;
    border: 0.069vw solid rgb(0, 137, 191);
    border-radius: 0.347vw;

    &:hover {
        color: rgb(0, 137, 191);
        background-color: rgba(0, 137, 191, 0.048);
        transition: 0.3s;
    }
}


@media screen and (max-width: 430px) {
    .request-camera-page {
        h1 {
            font-size: 6.9vw;
        }

        .forms {
            form {
                .form-container {
                    flex-direction: column;
                    gap: 4vw;
                    margin-bottom: 20vw;

                    .request-form-block {
                        p>b {
                            font-size: 5.05vw;
                        }

                        .camera-request-form1 label[for="email"],
                        .camera-request-form1 div:nth-child(7) {
                            order: 1;
                        }

                        .camera-request-form1 label[for="phone"],
                        .camera-request-form1 .react-tel-input {
                            order: 2;
                        }

                        .camera-request-form1 label[for="cameraBrand"],
                        .camera-request-form1 div:nth-child(11) {
                            order: 3;
                        }

                        .camera-request-form1 label[for="cameraIP"],
                        .camera-request-form1 div:nth-child(15) {
                            order: 4;
                        }

                        .camera-request-form1 {
                            display: flex;
                            flex-direction: column;
                            gap: 0;

                            label {
                                font-size: 4.44vw;
                                margin-bottom: 3vw;
                            }

                            div {

                                input {
                                    font-size: 4.44vw;
                                    width: 94%;
                                    padding: 1.38vw 2.16vw;
                                    border-radius: 1.41vw;
                                    margin-bottom: 6vw;
                                }

                                .error-message {
                                    font-size: 3.61vw;
                                    top: 8.5vw;
                                }
                            }

                            .react-tel-input {
                                margin-top: 1vw;

                                .form-control {
                                    padding-left: 10.5vw;
                                    font-size: 4.5vw;
                                    height: 10vw;
                                    width: 98.6%;
                                }

                                .flag-dropdown {
                                    .selected-flag {
                                        padding: 0 0 0 1.55vw;
                                        width: 10vw;

                                        .flag {
                                            margin-top: -1.3125vw;
                                        }
                                    }
                                }
                            }
                        }

                        .camera-request-form2 {
                            display: flex;
                            flex-direction: column;
                            gap: 0;

                            label {
                                font-size: 4.44vw;
                                margin-bottom: 3vw;
                            }


                            input {
                                font-size: 4.44vw;
                                width: 94%;
                                padding: 1.38vw 2.16vw;
                                border-radius: 1.41vw;
                                margin-bottom: 6vw;
                            }

                            textarea {
                                font-size: 4.44vw;
                                width: 94%;
                                height: 30vw;
                                padding-left: 2.16vw;
                                border-radius: 1.41vw;
                                margin-bottom: 6vw;
                            }

                            .error-message {
                                font-size: 3.61vw;
                                top: 8.5vw;
                            }
                        }
                    }
                }

                .form-submit {
                    bottom: -16.028vw;
                    padding: 2.625vw 2.25vw;
                    font-size: 5.111vw;
                    border-radius: 2.347vw;
                }
            }
        }
    }
}