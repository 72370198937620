.try-for-free {
    position: relative;
}

.try-for-free>img {
    width: 100%;
    height: 100%;
    pointer-events: none;
    user-select: none;
}

.try-for-free-content {
    position: absolute;
    align-items: center;
    display: flex;
    top: 25%;
    left: 35%;
}

.try-for-free-content>p {
    margin: 0 1.528vw 0 0;
    color: white;
    font-weight: 600;
    font-size: 1.389vw;
}

.try-for-free-content>a {
    text-decoration: none;
}

.trial-button {
    background-color: white;
    color: black;
    border-radius: 0.486vw;
    font-size: 1.042vw;
    font-weight: 600;
    padding: 0 2.014vw;
    border: 0;
    height: 2.5vw;
    display: flex;
    align-items: center;
    width: 100%;
}

@media screen and (max-width:430px) {
    .try-for-free {
        display: none;
    }
}