.characteristics{
    width: 100%;
    margin-left: 0vw;
    margin-top: 0.347vw;
    border: 0.139vw #aba9a9bf solid;
    border-radius: 1.25vw;
    grid-area: content;
    background-color: #ffffff;
}

@media only screen and (max-width: 430px) {
    .characteristics{
        width: 98%;
        border-radius: 5vw;
        margin-bottom: 4.375vw;
    }
    .sidecardheader{
        font-size: 4.375vw;
        padding: 4.375vw;
    }
    .sidecardsection>ul>li>a{
        font-size: 4.375vw;
    }
}