.mypcinfo {
    max-width: 87.5vw;
    margin: 0 auto;
    padding: 0 1.25vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        text-align: center;
        margin-bottom: 1.25vw;
        font-size: 1.875vw;
    }
}

.ip-map {
    width: 70%;
    margin-bottom: 2.014vw;
}

.about-ip {
    h2 {
        font-size: 1.389vw;
    }

    p {
        font-size: 1.25vw;
    }
}

.ip-table {
    width: 100%;
    margin-right: 2rem;
    border-collapse: collapse; 

    tbody>tr {
        th {
            text-align: start;
            font-size: 1.25vw;
            width: 44%;
            height: 45px;
        }

        td{
            width: 200px;
            font-size: 1.111vw;
            padding: 0.625vw 0;
        }
    }
}

.table-container{
    width: 100%;
    display: flex;
    gap: 2.014vw;
}

.info-table{
    width: 100%;
}