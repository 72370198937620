.contactus-header {
    padding: 2.2vw 17vw;
    text-align: center;
    background-color: #DDEAFF;
    border-bottom: 0.069vw solid #91bbfe;
}

.contactus-header>h1 {
    font-size: 2.125vw;
    margin-top: 0;
    margin-bottom: 0.347vw;
    color: #01183d;
}

.contactus-header>p {
    margin-top: 0;
    font-size: 1.2vw;
    font-weight: 400;
    color: #000000;
    opacity: 0.8;

    a {
        color: #007bff;
        text-decoration: none;
    }
}

.contactus-form {
    justify-content: center;
    max-width: 87.5vw;
    margin: 0 auto 4.861vw auto;
    padding: 0 1.25vw;
}

.contactus-main-form {
    margin-bottom: 1vw;
    display: grid;
    grid-template-columns: 47% 47%;
    grid-template-rows: auto auto auto auto;
    gap: 0.625vw;
    column-gap: 1.597vw;
}

.contactus-main-form>div {
    position: relative;
}

.contactus-main-form~label,
.contactus-main-form>label {
    font-size: 1.0625vw;
    font-weight: bold;
    color: black;
    margin-top: 1vw;
}

.contactus-main-form~label {
    margin-top: 0;
    margin-bottom: 9px;
    display: inline-flex;
}

.contactus-main-form~div>input {
    padding: 0.486vw 0.764vw;
    border: 0.069vw solid #8a7575;
    border-radius: 0.347vw;
    font-size: 0.9375vw;
    outline: none;
    width: 95%;
}

.contactus-main-form>div>input {
    padding: 0.486vw 0.764vw;
    border: 0.069vw solid #8a7575;
    border-radius: 0.347vw;
    font-size: 0.9375vw;
    outline: none;
    width: 90%;
}

.contactus-main-form {

    ~div {
        position: relative;
    }

    .react-tel-input .form-control {
        padding-left: 2.325rem;
        border: 0.069vw solid #8a7575;
        border-radius: 0.347vw;
        font-size: 1.25vw;
        margin-left: 0.347vw;
        width: 98%;
        height: 2.331vw;
        outline: none;
    }
}

.react-tel-input {
    .selected-flag {
        padding: 0 0 0 0.65vw;
        width: 3vw;

        .flag {
            margin-top: -0.4125vw;
            position: absolute;
            top: 50%;
            transform: scale(1.4);
        }
    }
}

.contactus-main-form>div>select {
    color: #8a7575;
    padding: 0.486vw 0.764vw;
    border: 0.069vw solid #8a7575;
    border-radius: 0.347vw;
    font-size: 1.25vw;
    overflow: auto;
    outline: none;
    width: 100%;
}

.contactus-main-form>div>select>option {
    color: black;
}

.contactus-main-form>div>select>option:checked {
    font-weight: bold;
}

.contactus-submit {
    padding: 1.25vw 2.5vw;
}

.contactus-submit>p:nth-child(1) {
    font-size: 1.806vw;
    color: #00418f;
    margin: 0;
}

.contactus-submit>p:nth-child(2) {
    font-size: 1.25vw;
    margin-top: 0.347vw;
}

.contactus-submit>form {
    position: relative;
}

.contactus-submit>form>button {
    position: absolute;
    font-size: 1.25vw;
    background-color: unset;
    font-weight: bold;
    padding: 0.486vw 1.528vw;
    color: #00418f;
    border: 0.139vw solid #00418f;
    border-radius: 1.597vw;
    bottom: -5vw;
    right: 0;

    &:focus {
        outline: none;
    }
}

.contact-message-box {
    margin-top: 1vw;
    resize: none;
    display: flex;
    flex-direction: column;
}

.contact-message-box-content {
    display: flex;
    justify-content: center;
    gap: 25.972vw;

}

.contact-message-box-content>p {
    margin: 0;
    font-size: 1.0625vw;
}

.contact-message-box-content>label {
    font-size: 1.0625vw;
    font-weight: bold;
}

.customer-message {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50%;
}

.customer-message>div {
    position: absolute;
    top: 11.528vw;
}

.customer-message>textarea {
    font-family: 'Inter', sans-serif;
    border: 0.069vw solid #8a7575;
    border-radius: 0.625vw;
    padding-left: 0.625vw;
    font-size: 0.9375vw;
    font-weight: 400;
    outline: none;
    resize: none;
    width: 100%;
    height: 10vw;
    margin-top: 0.347vw;
}

.customer-message>p {
    position: absolute;
    bottom: -10.347vw;
    color: red;
    font-size: 0.833vw;
    margin: 0;
    left: 0.694vw;
}

.error-message {
    position: absolute;
    color: red;
    font-size: 0.833vw;
    margin: 0;
    top: 2.5vw;
}

@media (hover: hover) and (any-hover: hover) and (min-width: 430px) {
    .contactus-header>p {
        a:hover {
            text-decoration: underline;
        }
    }

    .contactus-submit>form>button:hover {
        background-color: #00418f;
        color: white;
    }
}

@media screen and (max-width:430px) {
    .contactus-header {
        padding: 4.5vw 10vw;

        h1 {
            font-size: 8vw;
        }

        p {
            font-size: 4.5vw;
            text-align: center;
        }
    }

    .contactus-form {
        max-width: unset;
        display: flex;
        margin: 0 0 10vw 0;

        .contactus-submit {
            padding: 0;

            .error-message {
                font-size: 4vw;
                top: 11.8vw;
            }

            form {

                .contactus-main-form label[for="phone"],
                .contactus-main-form .react-tel-input {
                    order: 1;
                    margin-bottom: 1vw;
                }

                .contactus-main-form input[for="email"],
                .contactus-main-form div:nth-child(6) {
                    order: 2;
                }

                .contactus-main-form {
                    grid-template-columns: auto;
                    grid-template-rows: auto auto auto auto auto;
                    gap: 0.625vw;
                    column-gap: 1.597vw;
                    margin: 0;

                    div {
                        input {
                            margin: 1vw 0 3vw 0;
                            padding: 2.5vw 3.5vw;
                            font-size: 4.5vw;
                            border-radius: 1vw;
                        }

                        select {
                            padding: 2.5vw 3.5vw;
                            font-size: 4.5vw;
                        }
                    }

                    .react-tel-input {
                        margin-top: 1vw;

                        .form-control {
                            padding-left: 10.5vw;
                            font-size: 4.5vw;
                            height: 10vw;
                            width: 98.6%;
                        }

                        .flag-dropdown {
                            .selected-flag {
                                padding: 0 0 0 1.55vw;
                                width: 10vw;

                                .flag {
                                    margin-top: -1.3125vw;
                                }
                            }
                        }
                    }

                    label {
                        font-size: 4.5vw;
                        padding-top: 4vw;
                        margin-bottom: 1vw;
                        margin-top: 0;
                    }
                }

                label {
                    font-size: 4.5vw;
                    padding-top: 5vw;
                    margin-bottom: 2.3vw;
                }

                div {
                    input {
                        padding: 2.5vw 3.5vw;
                        font-size: 4.5vw;
                        width: 90%;
                        border-radius: 1vw;
                    }

                    #subject~.error-message {
                        font-size: 4vw;
                        top: 10.5vw;
                        left: 0;
                    }
                }

                .contact-message-box {
                    margin-bottom: 5vw;

                    .contact-message-box-content {
                        justify-content: space-between;
                        align-items: center;

                        label {
                            font-size: 4.5vw;
                            margin-bottom: 2vw;
                        }

                        p {
                            font-size: 4.5vw;
                            margin-top: 2vw;
                        }
                    }

                    .customer-message {
                        textarea {
                            padding: 2.5vw 3.5vw;
                            font-size: 4.5vw;
                            height: 20vw;
                            margin-bottom: 10vw;
                            border-radius: 1vw;
                        }

                        .error-message {
                            font-size: 4vw;
                            top: 25.5vw;
                            left: 0;
                        }
                    }
                }
            }

            button {
                font-size: 4.5vw;
                padding: 2.5vw 3.5vw;
                border-radius: 6vw;
                bottom: -4vw;
                right: 0;
                color: white;
                background-color: #00418f;
                cursor: pointer;
            }
        }
    }
}