.imp-feature {
    padding: 2.153vw 0.625vw 0 0.764vw;
    width: 99%;
    font-size: 1.0625vw;
}

.title-text {
    color: var(--text-color);
    font-size: 1.0625vw;
    font-weight: 600;
    margin-right: 0.625vw;
}

.list-text {
    color: var(--text-color);
    font-size: 1.0625vw;
    font-weight: 600;
    margin-bottom: 0.139vw;
}

.list-text>ul {
    margin-top: 0.625vw;
    padding-left: 1.597vw;
}

.version {
    display: flex;
    margin-bottom: 0.694vw;
}

.content-text {
    font-size: 1.0625vw;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
}

.version>a {
    margin-left: 0.625vw;
    font-weight: 400;
    font-size: 1.0625vw;
    color: var(--link-text);
    text-decoration-line: none;
}

.feature-link {
    font-weight: 400;
    font-size: 1.0625vw;
    color: var(--link-text);
    cursor: pointer;
}

.feature-list>ul>li::marker {
    color: var(--text-color);
    font-size: 1.25vw;
}

.feature-list {
    margin-top: 1.0625vw;
}

.feature-text {
    margin-top: 0.486vw;
    font-size: 1.0625vw;
}

.feature-list>ul {
    margin-top: 0.625vw;
    padding-left: 1.597vw;
}

@media (hover: hover) and (any-hover: hover) and (min-width: 430px) {
    .feature-link:hover {
        text-decoration-line: underline;
    }

    .version>a:hover {
        text-decoration-line: underline;
    }
}

@media screen and (max-width: 430px) {
    .imp-feature {
        padding: 10vw 0;
    }

    .title-text {
        font-size: 4.2vw;
    }

    .list-text {
        font-size: 4.2vw;
    }

    .content-text {
        font-size: 4.2vw;
        margin-left: 0.972vw;
    }

    .version {
        margin-bottom: 2.014vw;
    }

    .version>a {
        font-size: 4.375vw;
        margin-left: 5vw;
        font-weight: 400;
        color: var(--link-text);
        text-decoration-line: none;
    }

    .feature-link {
        display: inline-flex;
        margin: 2.986vw 0 0 0;
        left: 30%;
        position: relative;
        font-size: 4.375vw;
        font-weight: 600;
    }

    .feature-text {
        font-size: 4.375vw;
        margin-top: 2.014vw;
    }

    .feature-list {
        margin-top: 5vw;
    }

    .feature-list>ul {
        padding-left: 5vw;

        li::marker {
            font-size: 4.375vw;
        }
    }

}