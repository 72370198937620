.feature-title {
    font-size: 1.389vw;
    font-weight: 600;
    background-color: #d5eafd;
    color: black;
    padding: 1.25vw;
    border-top-right-radius: 1.25vw;
    border-top-left-radius: 1.25vw;
    border-bottom: 0.069vw #aba9a9bf solid;
    margin: 0vw;
}

.main-features {
    width: 100%;
}

.feature {
    display: flex;
    flex-direction: column;
    border-top: 0.069vw #aba9a9bf solid;
}

.feature-head {
    font-size: 1.111vw;
    font-weight: 600;
    margin: 1.528vw 0.972vw;
}


.feature-body {
    display: flex;
    padding: 0vw 1.736vw;
}

.flex-body-reverse {
    flex-direction: row-reverse;
}

.feature-body-list {
    width: 100%;
    align-items: baseline;
    display: flex;
    justify-content: flex-start;
    font-size: 1.042vw;

    ul{
        line-height: 1.5625vw;
    }
}

.feature-body-list-reverse {
    width: 100%;
    align-items: baseline;
    display: flex;
    justify-content: flex-start;
    font-size: 1.042vw;
    padding-left: 8.819vw;

    ul{
        line-height: 1.5625vw;
    }
}

.feature-body-image {
    width: 26.528vw;
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 0.972vw;
}

.feature-body-image>img {
    width: 100%;
    object-fit: fill;
    pointer-events: none;
}

.feature-body-image-reverse {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 5vw;
}

.feature-inner-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .feature-inner-list li {
    flex: 0 0 calc(25% - 0.625vw); /* 4 items per row, with 10px gap */
    margin: 0.347vw; /* half of the gap */
  }

@media only screen and (max-width: 430px) {
    .feature-title {
        font-size: 5.6vw;
        padding: 4.236vw;
        border-top-right-radius: 5vw;
        border-top-left-radius: 5vw;
    }

    .feature-head {
        font-size: 5.075vw;
        margin: 4.236vw 2.014vw;
    }

    .feature-body {
        flex-direction: column;
    }

    .feature-body-list {
        font-size: 4.575vw;
    }

    .feature-body-list-reverse {
        font-size: 4.575vw;
        padding-left: 0;
    }

    .feature-body-list>ul>li{
        margin: 2.014vw 5vw 2.014vw 0;
        line-height: 5.486vw;
    }

    .feature-body-list-reverse>ul>li {
        margin: 2.014vw 5vw 2.014vw 0;
    }

    .feature-body-image {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        padding-bottom: 4.236vw;
    }

    .feature-body-image-reverse {
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .flex-body-reverse {
        flex-direction: column;
    }
}