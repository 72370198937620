.customer-comments {
    margin: 0vw 0.764vw;
}

.comments-heading {
    font-size: 1.375vw;
    font-weight: 600;
    justify-content: space-between;
    padding: 0.764vw;
    display: flex;
    align-items: center;
}

.more-reviews {
    text-align: end;
    padding-right: 0.208vw;
    font-size: 1.042vw;
    color: #2980b9;
    text-decoration-line: none;
}

.more-reviews:hover {
    text-decoration: underline;
}

.comment-card-container {
    font-size: 0.972vw;
    display: grid;
    gap: 0.972vw;
    grid-template-columns: repeat(4, 1fr)
}

.comment-card {
    text-align: left;
    box-shadow: 0vw 0vw 0.208vw 0vw rgb(0 0 0 / 40%);
    padding: 1.25vw 1.25vw 0.972vw 1.25vw;
    border: 0.069vw solid #bbbbbbd0;
    border-radius: 1.25vw;
    display: block;
}

.comment-card>p {
    font-size: 1vw;
    margin-bottom: 0;
}

.comment-card>h4 {
    color: black;
    font-size: 1.125vw;
    margin-top: 0vw;
    margin-bottom: 1.25vw;
}

@media only screen and (max-width: 430px) {
    .comment-card-container {
        grid-template-columns: repeat(1, 1fr);
        gap: 4.931vw;
    }

    .comment-card {
        padding: 4.236vw;
        border-radius: 5vw;
    }

    .comment-card>h4 {
        font-size: 4.575vw;
    }

    .comment-card>p {
        font-size: 4.575vw;
    }

    .comments-heading {
        font-size: 5.572vw;
        flex-direction: column;
        align-items: stretch;
        margin: 5vw 0;
    }

    .comments-heading>p{
        margin: 0;
    }

    .more-reviews {
        font-size: 4.8vw;
        margin: 2.014vw;
    }

}