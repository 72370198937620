.image-button {
    position: relative;
}

.playerIcon {
    position: absolute;
    top: 44.5%;
    left: 28%;
}

.videoplayer-icon {
    height: 5vw;
    width: 5vw;
    left: 45%;
    top: 45%;
    position: absolute;
}