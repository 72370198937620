.footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    width: 100%;
    background: black;
    padding: 0.5vw 0;
    font-weight: 300;
    height: 3.403vw;
    margin-top: auto;
}

.footer-content {
    text-decoration: none;
    padding: 0;
    text-align: center;
    list-style: none;
    margin: auto;
}

.footer-content>span>a {
    color: white;
    font-size: 0.9vw;
    padding: 0.139vw;
    text-decoration: none;

    a {
        color: white;
        text-decoration: none;
    }
}

.end {
    color: white;
    display: flex;
    justify-content: center;
    font-size: 0.9vw;
}

@media only screen and (max-width: 430px) {
    .footer {
        flex-direction: row;
        padding-top: 1.25vw;
        padding-bottom: 1.25vw;
        height: auto;
        align-content: unset;

        .footer-content{
            margin: 2vw 0 3vw 0;

            span{
                a{
                    font-size: 4.028vw;
                }
            }
        }
    }

    .end {
        font-size: 3.5vw;
        margin: 2vw;
    }

}