.article {
    width: 70%;
    text-decoration: none;
    padding-left: 1.2vw;
    overflow-y: auto;
    max-height: calc(100vh - 36.617723vh - 4.915912031047865vh);

    ::-webkit-scrollbar {
        display: none;
    }

}

.articles-product-name {
    font-size: 1.5vw;
    color: #000;

    a {
        text-decoration: none;
        color: #000;

        &:hover {
            text-decoration: underline;
        }
    }
}

.article-title {
    font-size: 1.3vw;
    margin-bottom: 0;
    text-decoration: none;
    color: #0e48a7;

    &:hover {
        text-decoration: underline #0e48a7;
    }
}

.article-content {
    text-decoration: none;
}

.article-description {
    font-size: 1.15vw;
    color: #000;
    margin: 0;
    padding-bottom: 0.972vw;
    border-bottom: 0.069vw solid #5e5e5e;

    a {
        text-decoration: none;
        color: #3498db;

        &:hover {
            text-decoration: underline #3498db;
        }
    }

    span {
        p {
            margin: 0;

            img {
                display: none;
            }
        }
    }
}


@media screen and (max-width: 430px) {
    .article {
        width: 95%;
        padding: 2.014vw 2.528vw;
        font-size: 3vw;
        max-height: none;
        overflow-y: scroll;

        .articles-product-name {
            font-size: 5.1vw;
            margin: 1vw 0 1vw 0;
        }

        .article-content {
            .article-title {
                p {
                    font-size: 4.6vw;
                    margin-bottom: 2vw;
                }
            }

            .article-description {
                span {
                    font-size: 4.1vw;
                }

                a {
                    font-size: 4.1vw;
                }
            }
        }
    }

}