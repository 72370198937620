.side-by-side {
    display: flex;
    flex-wrap: wrap;

    background-color: rgba(42, 105, 202, 0.2);
    border-radius: 3vw;
    margin: 0vw 1vw;
    padding: 0 2.35vw 3vw 2.35vw;
}

.side-by-side:nth-child(1) {
    margin: 0vw 1vw;
    padding: 0 3.45vw 3vw 3.45vw;
}

.one-by-one {
    width: 96%;

    background-color: rgba(42, 105, 202, 0.2);
    border-radius: 3vw;
    margin-top: 2vw;
    padding-bottom: 3vw;
}

.product-cart-container {
    h3 {
        font-size: 1.4375vw;
        margin: 0;
        padding: 2vw 3vw;
        color: #212529;
        text-align: center;
    }
}

.products {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3.5vw;

}

.product-card {
    width: 25vw;
    height: 26vw;
    display: flex;
    flex-direction: column-reverse;
    border-radius: 1vw;
    background-color: #fff;
    box-shadow: 0 0.5vw 1vw rgba(0, 0, 0, 0.15);
}

.product-card>a>p {
    font-size: 0.89vw;
    margin: 0 1vw 1vw 1vw;
    color: black;
}

.product-card>button {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 0;
    position: relative;
    width: 80%;
    left: 10%;
    top: 2%;
}

.product-card>button>img {
    width: 100%;
    position: relative;
    bottom: -0.1875vw;
    object-fit: fill;
    pointer-events: none;
    border-top-left-radius: 1vw;
    border-top-right-radius: 1vw;
}

.product-card>button>a>div>img {
    width: 100%;
    position: relative;
    bottom: -0.1875vw;
    object-fit: fill;
    pointer-events: none;

}

.product-card>a {
    text-decoration: none;
    height: 7.5vw;
    padding: 0 2vw 0 2vw;
}

.button-block {
    display: flex;
    justify-content: center;
    gap: 1vw;
    padding: 1vw;

    .learn-more {
        text-decoration: none;
        background-color: white;
        color: #007bff;
        border: none;
        padding: 0.5vw 2.5vw;
        border-radius: 0.5vw;
        border: 0.1vw solid #007bff;
        font-size: 1vw;
        cursor: pointer;
    }

    .download {
        text-decoration: none;
        background-color: #007bff;
        color: white;
        border: none;
        padding: 0.5vw 2.5vw;
        border-radius: 0.5vw;
        font-size: 1vw;
        cursor: pointer;
    }
}

.card-image {
    width: 25%;
    background-color: transparent;
    box-shadow: none;
}

.card-image>img {
    width: 100%;
    pointer-events: none;
    user-select: none;
}

.card-title {
    display: flex;
    font-size: 1.5rem;
    margin: 1vw 0 0 1vw;
    font-weight: 500;
    color: #212529;
    align-items: center;
}

.card-title>h4 {
    font-size: 1.4375vw;
    margin: 1vw 0 1vw 1vw;
    padding: 0;

}

.card-icon {
    width: 10%;
}

.play-icon {
    z-index: 1;
    left: 43%;
    top: 40%;
    position: absolute;
}

@media (hover: hover) and (any-hover: hover) and (min-width: 430px) {
    .product-card:hover {
        box-shadow: 0vw 0vw 0.625vw 0.125vw rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }
}

@media screen and (max-width:430px) {

    .card-title {
        font-size: 5vw;

        h4 {
            margin-bottom: 2vw;
            font-size: 4.8vw;
        }

        .card-icon {
            width: 10%;
        }
    }

    .side-by-side {
        width: 96%;
        padding: 0;
        padding-bottom: 5vw;
        margin: 5vw 2vw;
    }

    .side-by-side:nth-child(1) {
        width: 96%;
        padding: 0;
        padding-bottom: 5vw;
        margin: 5vw 2vw;
    }

    .one-by-one {
        width: 96%;
        margin: 2vw 0 5vw 2vw;
        padding: 0;
        padding-bottom: 5vw;
    }

    .product-cart-container {
        width: 100%;

        h3 {
            font-size: 5vw;
            padding: 5vw 7vw;
            font-weight: 400;
        }
    }

    .products {
        flex-direction: column;
    }

    .product-card {
        width: 85vw;
        height: 100%;
        border-radius: 2vw;
        flex-direction: row-reverse;
        border: 0.1vw solid black;


        a {
            height: unset;
            width: 70%;
            margin: 1vw;
            padding: 0;

            p {
                font-size: 3.8vw;
            }
        }

        button {
            width: 30%;
            padding: 1vw;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;

            img {
                /* border-top-left-radius: 2vw;
                border-bottom-left-radius: 2vw;
                border-bottom-right-radius: 0;
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 99%; */
            }

            svg {
                width: 5vw;
                height: 5vw;
                z-index: 1;
                left: 40%;
                top: 40%;
                position: absolute;
            }

            a {
                margin: 0;

                div {
                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-top-left-radius: 2vw;
                        border-bottom-left-radius: 2vw;
                        object-fit: cover;
                        object-position: center;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

    }

    .button-block {
        display: none;
    }
}